import React, { useState, useEffect } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

const TradesSweepsCheckBoxes = ({ 
    splitsChecked, 
    sweepsChecked, 
    blocksChecked, 
    multilegChecked, 
    exDividendChecked, 
    handleTradeSweepChange 
}) => {
    const [splitsState, setSplitsState] = useState(splitsChecked);
    const [sweepsState, setSweepsState] = useState(sweepsChecked);
    const [blocksState, setBlocksState] = useState(blocksChecked);
    const [multilegState, setMultilegState] = useState(multilegChecked);
    const [exDividendState, setExDividendState] = useState(exDividendChecked);

    const handleSplitsChange = (isChecked) => {
        console.log("Splits change");
        setSplitsState(isChecked);
        handleTradeSweepChange(isChecked, 'splitsCheckbox');
    };

    const handleSweepsChange = (isChecked) => {
        console.log("Sweeps change");
        setSweepsState(isChecked);
        handleTradeSweepChange(isChecked, 'sweepsCheckbox');
    };

    const handleBlocksChange = (isChecked) => {
        console.log("Blocks change");
        setBlocksState(isChecked);
        handleTradeSweepChange(isChecked, 'blocksCheckbox');
    };

    const handleMultilegChange = (isChecked) => {
        console.log("Multileg change");
        setMultilegState(isChecked);
        handleTradeSweepChange(isChecked, 'multiLegCheckbox');
    };

    const handleExDividendChange = (isChecked) => {
        console.log("Ex-Dividend change");
        setExDividendState(isChecked);
        handleTradeSweepChange(isChecked, 'exDividendCheckbox');
    };

    useEffect(() => {
        setSplitsState(splitsChecked);
    }, [splitsChecked]);

    useEffect(() => {
        setSweepsState(sweepsChecked);
    }, [sweepsChecked]);

    useEffect(() => {
        setBlocksState(blocksChecked);
    }, [blocksChecked]);

    useEffect(() => {
        setMultilegState(multilegChecked);
    }, [multilegChecked]);

    useEffect(() => {
        setExDividendState(exDividendChecked);
    }, [exDividendChecked]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <Checkbox.Root
                        checked={sweepsState}
                        onCheckedChange={handleSweepsChange}
                        style={{
                            backgroundColor: sweepsState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{ color: 'white', width: '8px', height: '8px' }} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.75rem' }}>Sweeps</label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <Checkbox.Root
                        checked={blocksState}
                        onCheckedChange={handleBlocksChange}
                        style={{
                            backgroundColor: blocksState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{ color: 'white', width: '8px', height: '8px' }} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.75rem' }}>Blocks</label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <Checkbox.Root
                        checked={multilegState}
                        onCheckedChange={handleMultilegChange}
                        style={{
                            backgroundColor: multilegState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{ color: 'white', width: '8px', height: '8px' }} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.75rem' }}>Multileg</label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox.Root
                        checked={exDividendState}
                        onCheckedChange={handleExDividendChange}
                        style={{
                            backgroundColor: exDividendState ? 'black' : 'transparent',
                            width: '14px',
                            height: '14px',
                            borderRadius: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '8px',
                            border: '1px solid #6B7280',
                        }}
                    >
                        <Checkbox.Indicator>
                            <CheckIcon style={{ color: 'white', width: '8px', height: '8px' }} />
                        </Checkbox.Indicator>
                    </Checkbox.Root>
                    <label style={{ color: 'white', fontSize: '0.75rem' }}>Ex-Dividend</label>
                </div>
            </div>
        </div>
    );
};

export default TradesSweepsCheckBoxes;