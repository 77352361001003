
import { makeStyles } from "@material-ui/core";
import { getSentimentText, isDeepOTM, optionColorShouldBeGreen, optionColorShouldBeGreenOnlyType, formatMoney, getBullflowColorPrefCB, getBullflowTimezonePref } from "./Utils";
import { Tooltip } from "@nextui-org/react";
import { LockClosedIcon } from "@radix-ui/react-icons";
const bullflowColorPref = getBullflowColorPrefCB();
const bullflowLocalTimezonePref = getBullflowTimezonePref();
const BULLFLOW_GREEN = "#20d46b";
const BULLFLOW_RED = "#cc3753";
const BULLFLOW_OPACITY_GREEN = "rgba(2, 212, 44, 0.15)";
const BULLFLOW_OPACITY_RED = "rgba(250, 0, 67, 0.15)";
const CB_OPACITY_GREEN = "rgba(44, 140, 90, 0.15)";
const CB_OPACITY_RED = "rgba(211, 84, 0, 0.15)";
const CB_GREEN = "#2c8c5a";
const CB_RED = "#d35400";
const GREEN_ = bullflowColorPref ? CB_GREEN : BULLFLOW_GREEN;
const RED_ = bullflowColorPref ? CB_RED : BULLFLOW_RED;
const OPACITY_GREEN_ = bullflowColorPref ? CB_OPACITY_GREEN : BULLFLOW_OPACITY_GREEN;
const OPACITY_RED_ = bullflowColorPref ? CB_OPACITY_RED : BULLFLOW_OPACITY_RED;

const tooltips = {
    SWEEP: "Executed rapidly across multiple markets simultaneously to take advantage of the best available prices.",
    BLOCK: "Executed on single exchange. Larger trades may be negotiated off the exchange.",
    SPLIT: "Executed across two markets to achieve better price realization and liquidity.",
    MULTI: "A multileg trade involving the buying or selling of multiple options in a single transaction.",
};

const useStyles = makeStyles({
    dataTypeWhite: {
        color: 'white'
    },
    dataTypeBlue: {
        color: '#ffa42e '
    },
    logo: {
        height: '15px',
        margin: 0,
        padding: 0,
        marginRight: '10px',
        cursor: 'pointer',
    },
    dataTypeGreen: {
        color: GREEN_,
        fontWeight: 500,
    },
    dataTypeRed: {
        color: RED_,
        fontWeight: 500,
    },
});

const GuestLockCellRenderer = (params) => {
    // Just show a lock icon
    return (
        <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
            <Tooltip closeDelay={1} delay={1250} content="Subscribe to unlock this data">
                <LockClosedIcon />
            </Tooltip>
        </div>
    );
};


const TickerCellRenderer = (params) => {
    const value = params?.value;
    const ot = params?.data?.ot;
    const optionColorShouldBeGreen_ = optionColorShouldBeGreenOnlyType(ot);
    const greenType = bullflowColorPref ? CB_GREEN : BULLFLOW_GREEN;
    const redType = bullflowColorPref ? CB_RED : BULLFLOW_RED;
    const OPACITY_GREEN_ = bullflowColorPref ? CB_OPACITY_GREEN : BULLFLOW_OPACITY_GREEN;
    const OPACITY_RED_ = bullflowColorPref ? CB_OPACITY_RED : BULLFLOW_OPACITY_RED;
    const wrapperStyle = {
        borderRadius: '5px',
        color: '#fff',
        backgroundColor: optionColorShouldBeGreen_ ? OPACITY_GREEN_ : OPACITY_RED_,
        borderColor: optionColorShouldBeGreen_ ? greenType : redType,
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'inline-block',
        padding: '5px 5px',
        lineHeight: '15px',
        width: '60px',
        textAlign: 'center',
    };

    return (
        <div style={wrapperStyle}>{value}</div>
    );
};

const OptionTypeCellRenderer = (params) => {
    const value = params?.value;
    const ot = params?.data?.ot;
    const classes = useStyles();
    return (
        <div className={`${optionColorShouldBeGreenOnlyType(ot) ? classes.dataTypeGreen : classes.dataTypeRed} ${classes.cell}`}>
            {value === "P" ? "Put" : "Call"}
        </div>
    );
}

const DeltaCellRenderer = (params) => {
    const value = params?.data?.g?.d?.toFixed(3);
    return (
        <div>
            {value}
        </div>
    );
}


const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
const TradeTypeCellRenderer = (params) => {
    let value = params?.value;
    if (params?.data?.im) {
        value = "MULTI";
    }
    const style = {
        color: value === "SWEEP" ? '#e3c102' :
            value === "BLOCK" ? '#027df7' :
                value === "SPLIT" ? '#b223fa' : 'white'
    };
    const displayValue = capitalizeFirstLetter(value);

    const tooltip = tooltips[value.toUpperCase()] || "Standard trade type";
    return (
        <Tooltip closeDelay={1} delay={1250} content={tooltip}>
            <div style={style}>
                {displayValue}
            </div>
        </Tooltip>
    );
}

const OpenInterestCellRenderer = (params) => {
    let value = params?.value;
    return (
        <div >
            {value == null ? "N/A" : value}
        </div>
    );
}

const TickerSentimentCellRenderer = (params) => {
    const value = params?.value;
    const classes = useStyles();
    return (
        <div className={value === 1 ? classes.dataTypeGreen :
            value === -1 ? classes.dataTypeRed :
                classes.dataTypeWhite}>
            {getSentimentText(value)}
        </div>
    )
}

const TradePriceCellRenderer = (params) => {
    const value = params?.value;
    return (
        <div>
            {value}
        </div>
    );
}

const TradeSizeCellRenderer = (params) => {
    const size = params?.value;
    const oi = params?.data?.oi;
    const volume = params?.data?.cv;
    if (oi != null && size > oi) {
        // return text that is #29ab8a greenish
        return (
            <Tooltip closeDelay={1} delay={1000} content="Trade size is greater than open interest. Unusual.">
                <div style={{ color: '#19cfa0' }}>
                    {size}
                </div>
            </Tooltip>
        );
    } else if (volume != null && oi != null && volume > oi) {
        //return text that is #ab299c purpleish
        return (
            <Tooltip closeDelay={1} delay={1250} content="Contract volume is greater than open interest.">
                <div style={{ color: '#d117bb' }}>
                    {size}
                </div>
            </Tooltip>
        );
    } else {
        return (
            <div>
                {size}
            </div>
        );
    }
}


const UnixTimestampCellRenderer = (params) => {
    const value = params?.value;

    // Configure date options based on timezone preference
    const dateOptions = {
        hour12: false,
        timeZone: bullflowLocalTimezonePref ? undefined : 'America/New_York' // undefined uses local timezone
    };

    // Tooltip content changes based on timezone preference
    const tooltipContent = bullflowLocalTimezonePref 
        ? "Time of trade, local timezone" 
        : "Time of trade, EST Time zone";

    return (
        <Tooltip closeDelay={1} delay={1250} content={tooltipContent}>
            <div style={{ color: '#858585' }}>
                {new Date(value * 1000).toLocaleTimeString('en-US', dateOptions)}
            </div>
        </Tooltip>
    );
};

const HistoricalFlowDateTimeCellRenderer = (params) => {
    const value = params?.value;
    return (
        <Tooltip closeDelay={1} delay={1250} content="Time of trade, EST Time zone">
            <div>
                {new Date(value * 1000).toLocaleString('en-US', {
                    timeZone: 'America/New_York',
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                })}
            </div>
        </Tooltip>
    );
};
const OTMCellRenderer = (params) => {
    const value = params?.value;
    return (
        <div>
            {value}
        </div>
    );
}

const TrendingContractPremiumCellRenderer = (params) => {
    const value = params?.value;
    const classes = useStyles();
    return (
        <div className={`${classes.dataTypeWhite}  ${classes.cell}`}>
            {'$' + formatMoney(value)}
        </div>
    );
}

const PremiumCellRenderer = (params) => {
    const value = params?.value;
    const classes = useStyles();
    return (
        <div className={`${classes.dataTypeWhite}  ${classes.cell}`}>
            {'$' + formatMoney(value)}
        </div>
    );
}

const EndOfDayPerformanceCellRenderer = (params) => {
    const value = params?.value;
    const classes = useStyles();
    return (
        <Tooltip closeDelay={1} delay={1250} content="% return of this trade at EOD">

            <div className={`${value !== undefined ? (value > 0 ? classes.dataTypeGreen : classes.dataTypeRed) : classes.dataTypeWhite}  ${classes.cell}`}>
                {value !== undefined ? (value >= 0 ? '+' + value + '%' : value + '%') : 'N/A'}
            </div>
        </Tooltip>
    )
}

const SigScoreCellRenderer = (params) => {
    let value = params?.value;
    if (typeof value === 'number') {
        value = Number(value.toFixed(2));
    }



    if (!value) {
        return null;
    }
    let color;
    if (value <= 0.2) {
        color = '#fa0043';
    } else if (value > 0.2 && value <= 0.3) {
        color = '#f71707';
    } else if (value > 0.3 && value <= 0.4) {
        color = '#f74f07';
    } else if (value > 0.4 && value <= 0.5) {
        color = '#f76f07';
    } else if (value > 0.5 && value <= 0.6) {
        color = '#f79307';
    } else if (value > 0.6 && value <= 0.7) {
        color = '#f7cf07';
    } else if (value > 0.7 && value <= 0.8) {
        color = '#c3f707';
    } else if (value > 0.8 && value <= 0.89) {
        color = '#73f707';
    } else if (value > 0.89 && value <= 0.95) {
        color = '#24e00b'
    } else if (value > 0.95 && value <= 1.00) {
        color = '#25ff08'
    } else {
        color = '#FFD700';
    }

    let percentage = value * 100;

    return (
        <Tooltip closeDelay={1} delay={1250} content="Indicates how unusual the trade is. The closer to 1.0, the more unusual.">
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
            }}>
                <div style={{
                    width: '80%',
                    height: '8px',
                    borderRadius: '10px',
                    background: '#15111f',
                    fontSize: '0.65em',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <div style={{
                        width: `${percentage}%`,
                        height: '10px',
                        backgroundColor: color,
                        borderRadius: '10px',
                        marginRight: '2px'
                    }}>
                        {value}
                    </div>
                </div>
            </div>
        </Tooltip>
    );
}

const SpeadExecutionCellRenderer = (params) => {
    const classes = useStyles();
    const executionValue = params?.value;
    return (
        <Tooltip closeDelay={1} delay={1250} content={
            executionValue === "Above Ask" ? "Executed Above Ask" :
                executionValue === "Below Bid" ? "Executed Below Bid" :
                    executionValue === "Bid" ? "Executed at/near bid" :
                        executionValue === "Ask" ? "Executed at/near ask" :
                            "Executed at/near Mark"
        }>
            <div className={classes.dataTypeWhite}>
                {executionValue === "Above Ask" ? "AA" :
                    executionValue === "Below Bid" ? "BB" :
                        executionValue === "Below Bid" ? "BB" :
                            executionValue === "Below Bid" ? "BB" :
                                executionValue}
            </div>
        </Tooltip>
    )
}

const ImpliedVolatilityCellRenderer = (params) => {
    const value = params?.value;
    const parsedValue = parseFloat(value);

    return (
        <div>
            {value == null || isNaN(parsedValue) ? "N/A" : parsedValue}
        </div>
    );
}


const CurrentVolumeCellRenderer = (params) => {
    const value = params?.value;
    return (
        <Tooltip closeDelay={1} delay={1250} content="Total volume of the contract at time of execution">
            <div>
                {value === "" || value === null ? "N/A" : value}
            </div>
        </Tooltip>
    );
}

const PreviousVolumeCellRenderer = (params) => {
    const value = params?.value;
    return (
        <Tooltip closeDelay={1} delay={1250} content="Yesterday total volume">
            <div>
                {value === "" ? "N/A" : value}
            </div>
        </Tooltip>
    );
}
export { DeltaCellRenderer, TradeSizeCellRenderer, TradeTypeCellRenderer, EndOfDayPerformanceCellRenderer, HistoricalFlowDateTimeCellRenderer, GuestLockCellRenderer, TrendingContractPremiumCellRenderer, ImpliedVolatilityCellRenderer, OpenInterestCellRenderer, CurrentVolumeCellRenderer, PreviousVolumeCellRenderer, SpeadExecutionCellRenderer, SigScoreCellRenderer, PremiumCellRenderer, OTMCellRenderer, UnixTimestampCellRenderer, TradePriceCellRenderer, TickerSentimentCellRenderer, TickerCellRenderer, OptionTypeCellRenderer };
