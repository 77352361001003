// SectorDropDown.jsx
import React, { useState } from 'react';
import { Select, SelectItem } from '@nextui-org/react';
import PropTypes from 'prop-types';
const sectors = [
    "ALL",
    "Healthcare",
    "Financial Services",
    "Basic Materials",
    "Consumer Defensive",
    "Industrials",
    "Technology",
    "Consumer Cyclical",
    "Real Estate",
    "Communication Services",
    "Energy",
    "Utilities",
    "Other"
];


const SectorDropDown = ({ handleDropdownChange }) => {
    // Set default selected value to "ALL"
    const [label, setLabel] = useState('ALL');

    const handleDropDownChangeLocal = (e) => {
        setLabel(e.target.value);
        // console.log(`Selected sector: ${e.target.value}`);
        handleDropdownChange(sectors[e.target.value]);
    };

    return (
        <div style={{ width: "100%" }}>
            <div style={{
                textAlign: "left", marginBottom: "8px",
                fontWeight: 600,
                fontSize: '0.8em',
            }}>
                <h3>Sector</h3>
            </div>
            <Select
                size="sm"
                color="default"
                label={label}
                placeholder="Select a sector"
                selectionMode="single"
                onChange={handleDropDownChangeLocal}
                width="100%"
            >
                {sectors.map((sector, index) => (
                    <SelectItem key={index} value={sector}>
                        {sector}
                    </SelectItem>
                ))}
            </Select>
        </div>
    );
};


SectorDropDown.propTypes = {
    handleDropdownChange: PropTypes.func.isRequired,
};

export default SectorDropDown;
