import { CircularProgress, MenuItem, Select, styled } from '@material-ui/core';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { collection, query, orderBy, getDocs, where } from "firebase/firestore";
import { firestore } from './firebase';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, ComposedChart, Bar, Legend } from 'recharts';
import { convertOptionsSymbol, getDateString2, parseOptionType, parseTickerFromOptionsSymbol } from './Utils';
import { FormControl } from '@mui/material';
import { Tooltip as NextUITooltip } from "@nextui-org/react";

const WhiteSelect = styled(Select)(({ theme }) => ({
    backgroundColor: '#4a4a4a',
    color: 'white',
    '&:hover': {
        backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
    },
    '& .MuiSelect-icon': {
        color: '#4a4a4a',
    },
}));
const WhiteMenuItem = styled(MenuItem)({
    backgroundColor: 'white',
    color: '#4a4a4a',
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
    '&.Mui-selected': {
        backgroundColor: '#e0e0e0',
        '&:hover': {
            backgroundColor: '#d0d0d0',
        },
    },
});
const getOptionsPriceData = async (optionsTicker, from, to, timespan, multiplier) => {
    const url = new URL('https://api.bullflow.io/v1/getOptionsPriceData');

    if (from === to) {
        timespan = 'minute';
        multiplier = 1;
    }

    // Append query parameters to the URL
    url.searchParams.append('optionsTicker', optionsTicker);
    url.searchParams.append('from', from);
    url.searchParams.append('to', to);
    url.searchParams.append('timespan', timespan);
    url.searchParams.append('multiplier', multiplier);

    // Fetch data using GET method
    try {
        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            console.error('Network response was not ok:', response.statusText);
            return []; // Return an empty array or handle the error as needed
        }

        let data = await response.json();

        if (!Array.isArray(data)) {
            console.error('Expected data to be an array, but got:', data);
            return []; // Return an empty array or handle the error as needed
        }

        // Proceed with data processing
        let filteredData = null;
        if (from !== to) {
            filteredData = data.reduce((acc, curr) => {
                const date = new Date(curr.time).toDateString();
                if (!acc[date] || curr.time > acc[date].time) {
                    acc[date] = curr;
                }
                return acc;
            }, {});
        } else {
            try {
                data = data.filter(item => item.volume > 0);
                // filter out data with a volume of zero
                // remove every other data point to reduce the number of points if data length is greater than 250
                if (data.length > 250) {
                    data = data.filter((_, index) => index % 2 === 0);
                }
            } catch (error) {
                console.error('Error filtering data:', error);
            }
            filteredData = data;
        }

        // Convert the filtered object back to an array if needed.
        const result = Array.isArray(filteredData) ? filteredData : Object.values(filteredData);

        // Replace 0 values in the vwap field with null.
        const modifiedResult = result.map(item => ({
            ...item,
            vwap: item.vwap === 0 ? null : item.vwap,
        }));

        return modifiedResult;
    } catch (error) {
        console.error('Fetch error:', error);
        return []; // Return an empty array or handle the error as needed
    }
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const dateObject = new Date(label);
        const options = {
            timeZone: 'America/New_York', // EST timezone
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };

        const time = dateObject.toLocaleTimeString('en-US', options);

        const volumePayload = payload.find(p => p.dataKey === 'volume');
        const volume = volumePayload ? volumePayload.value : 'N/A';
        const openInterestPayload = payload.find(p => p.dataKey === 'openInterest');
        const openInterest = openInterestPayload ? openInterestPayload.value : 'N/A';
        const vwapPayload = payload.find(p => p.dataKey === 'vwap');

        const vwap = vwapPayload ? vwapPayload.value.toFixed(2) : 'N/A';

        return (
            <div style={{
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'rgba(106, 108, 138, 0.8)',
                padding: '8px',
            }}>
                <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`Time: ${time} EST`}</p>
                <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`Avg Price: ${vwap}`}</p>
                <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`Volume: ${volume}`}</p>
                <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`OI: ${openInterest}`}</p>
            </div>
        );
    }

    return null;
};

const OptionPriceChart = ({ optionsTicker_ }) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const initFromDate = currentDate.toISOString().split('T')[0];
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [firestoreData, setFirestoreData] = useState([]); // New state for Firestore data
    const [activePeriod, setActivePeriod] = useState('1M');
    const [activeTimespan, setActiveTimespan] = useState('hour');
    const [timespanValue, setTimespanValue] = useState('8 Hour');
    const [multiplier, setMultiplier] = useState(1);
    const [from, setFrom] = useState(initFromDate);
    const [period, setPeriod] = useState('1M');
    // const to = new Date().toISOString().split('T')[0];
    const to = getDateString2();
    const [lowestStockPrice, setLowestStockPrice] = useState(0.0);
    const [highestStockPrice, setHighestStockPrice] = useState(0.0);
    const [yAxisDomain, setYAxisDomain] = useState([0, 0]);
    let optionsTicker = optionsTicker_;
    const selectRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        getOptionsPriceData(optionsTicker, from, to, activeTimespan, multiplier)
            .then(response => {
                setData(response);
                setLoading(false);
            });
    }, [optionsTicker, from, to, multiplier, activeTimespan]);

    const formatXAxis = (tickItem) => {
        const date = new Date(tickItem);
        if (activePeriod === '1D') {
            return date.toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour: '2-digit', minute: '2-digit', hour12: false });
        } else {
            return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
        }
    };
    const numberFormatter2 = (number) => {
        return number.toFixed(2);
    };
    const CustomTooltip2 = ({ active, payload, label }) => {
        // Memoize the formatted date to prevent re-computation on every render
        const formattedTime = useMemo(() => {
            if (label) {
                const dateObject = new Date(label * 1000);
                const options = {
                    timeZone: 'America/New_York',
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };
                return dateObject.toLocaleTimeString('en-US', options);
            }
            return '';
        }, [label]);

        if (active && payload && payload.length) {
            const callsNetPremium = payload.find(p => p.dataKey === 'callsNetPremium');
            const putsNetPremium = payload.find(p => p.dataKey === 'putsNetPremium');
            const stockPrice = payload.find(p => p.dataKey === 'stockPrice');

            return (
                <div style={{
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'rgba(106, 108, 138, 0.8)',
                    padding: '8px',
                }}>
                    <p className="label" style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`${formattedTime} EST`}</p>
                    {callsNetPremium && <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`Net Call Premium: ${numberFormatter(callsNetPremium.value)}`}</p>}
                    {putsNetPremium && <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`Net Put Premium: ${numberFormatter(putsNetPremium.value)}`}</p>}
                    {stockPrice && <p style={{ fontWeight: 500, fontSize: '0.8em', color: 'white', marginBottom: '0px', marginTop: '8px' }}>{`Price: ${stockPrice.value.toFixed(2)}`}</p>}
                </div>
            );
        }

        return null;
    };
    const handlePeriodClick = (value) => {
        setActivePeriod(value);
        setPeriod(value);
        let newFromDate;

        switch (value) {
            case '1D':
                // Use getDateString to determine the 'from' date based on trading day logic
                newFromDate = getDateString2();
                break;
            case '7D':
                {
                    const sevenDaysAgo = new Date();
                    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
                    newFromDate = sevenDaysAgo.toISOString().split('T')[0];
                }
                break;
            case '1M':
                {
                    const oneMonthAgo = new Date();
                    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
                    newFromDate = oneMonthAgo.toISOString().split('T')[0];
                }
                break;
            case '3M':
                {
                    const threeMonthsAgo = new Date();
                    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
                    newFromDate = threeMonthsAgo.toISOString().split('T')[0];
                }
                break;
            case '1Y':
                {
                    const oneYearAgo = new Date();
                    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                    newFromDate = oneYearAgo.toISOString().split('T')[0];
                }
                break;
            case 'MAX':
                {
                    const fiveYearsAgo = new Date();
                    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);
                    newFromDate = fiveYearsAgo.toISOString().split('T')[0];
                }
                break;
            default:
                {
                    // Default to today's date if an unrecognized period is selected
                    const today = new Date();
                    newFromDate = today.toISOString().split('T')[0];
                }
                break;
        }

        setFrom(newFromDate);
    };


    const handleTimespanClick = (value) => {
        setTimespanValue(value);
        switch (value) {
            case '5 Min':
                setActiveTimespan("minute");
                setMultiplier(5);
                break;
            case '15 Min':
                setActiveTimespan("minute");
                setMultiplier(15);
                break;
            case '1 Hour':
                setActiveTimespan("hour");
                setMultiplier(1);
                break;
            case '8 Hour':
                setActiveTimespan("hour");
                setMultiplier(8);
                break;
            default:
                break;
        }
    };
    const formatXAxis2 = (tickItem) => {
        const milliseconds = Math.floor(tickItem * 1000);
        const date = new Date(milliseconds);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yy = String(date.getFullYear()).slice(-2);
        return `${mm}/${dd}`;
    };
    const numberFormatter = (number) => {
        const absNumber = Math.abs(number);

        let formattedNumber;
        if (absNumber >= 1000 && absNumber < 1000000) {
            formattedNumber = `${(absNumber / 1000).toFixed(1).replace(/\.0$/, '')}k`;
        } else if (absNumber >= 1000000) {
            formattedNumber = `${(absNumber / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
        } else {
            formattedNumber = absNumber.toString();
        }

        // Reapply the sign to the formatted number
        return number < 0 ? `-${formattedNumber}` : formattedNumber;
    };

    return (
        <>
            {loading ? (
                <div>
                    <CircularProgress style={{ padding: '0px', margin: '0px' }} />
                </div>
            ) : (
                <div style={{ textAlign: 'center', height: 'auto', width: '100%' }}>
                    <div style={{ display: 'flex', height: 'auto', justifyContent: 'space-between', alignItems: 'center', color: 'white', marginBottom: '8px' }}>
                        <p style={{ margin: 0, textAlign: 'right', color: 'white', fontWeight: 500 }}>
                            <span style={{ color: parseOptionType(optionsTicker).includes('P') ? '#fa0043' : '#02d42c' }}>
                                {convertOptionsSymbol(optionsTicker)}
                            </span>
                        </p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControl variant="outlined" size="small" style={{ color: 'white', borderColor: 'white' }}>
                                <Select
                                    labelId="time-period-select-label"
                                    id="time-period-select-2"
                                    value={activePeriod}
                                    onChange={(event) => handlePeriodClick(event.target.value)}
                                    MenuProps={{ style: { color: 'white' } }}
                                    disableUnderline
                                    inputProps={{
                                        style: { color: 'blue' }
                                    }}
                                    style={{ color: '#4a4a4a', borderColor: 'white' }}
                                >
                                    <MenuItem value="1D">1D</MenuItem>
                                    <MenuItem value="7D">7D</MenuItem>
                                    <MenuItem value="1M">1M</MenuItem>
                                    <MenuItem value="3M">3M</MenuItem>
                                    <MenuItem value="1Y">1Y</MenuItem>
                                    <MenuItem value="MAX">MAX</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>                        <div>
                        <h3 style={{ textAlign: 'center', color: 'white' }}>Contract Volume, Open Interest & Price</h3>
                        <ComposedChart
                            width={600}
                            height={400}
                            data={data}
                            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        >
                            <XAxis dataKey="time" tickFormatter={formatXAxis} />
                            <YAxis yAxisId="left" orientation="left" stroke="#FFD700" />
                            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                            <Tooltip content={<CustomTooltip />} />
                            <CartesianGrid stroke="#2a233d" vertical={false} />
                            <Bar yAxisId="right" dataKey="volume" fill="#82ca9d" name="Volume" />
                            <Bar yAxisId="right" dataKey="openInterest" fill="#8884d8" name="Open Interest" />
                            <Line
                                yAxisId="left" type="monotone" dataKey="vwap"
                                stroke="#FFD700" strokeWidth={2.25} name="Avg Price"
                                connectNulls={false}
                                dot={({ cx, cy, value }) => {
                                    // Only render a dot if the value is not null
                                    if (value !== null) {
                                        return <circle cx={cx} cy={cy} r={2.5} fill="#FFD700" />;
                                    }
                                    return null;
                                }}
                            />
                            <Legend
                                wrapperStyle={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    marginTop: '10px',
                                }}
                            />
                        </ComposedChart>
                    </div>
                    </div>
                </div>
            )}
        </>
    );

};

export default OptionPriceChart;
