// FilterSelect.jsx
import React, { useState } from 'react';
import { Select, SelectItem } from '@nextui-org/react';
import PropTypes from 'prop-types';

const FilterSelect = ({ filterNames, handleDropdownChange, deleteFilter }) => {
    const [label, setLabel] = useState('Select a filter');
    const handleDropDownChangeLocal = (e) => {
        setLabel(e.target.value);
        handleDropdownChange(e);
    };
  return (
    <Select
      size="sm"
      color="default"
      label={label}
      placeholder="Select a filter"
      selectionMode="single"
      onChange={handleDropDownChangeLocal}
      width="80%"
    >
      {filterNames.map((filter) => (
        <SelectItem key={filter} value={filter}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {filter}
            <span
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the Select change event
                deleteFilter(filter);
              }}
              style={{ cursor: 'pointer', color: 'red', marginLeft: '20px' }}
            >
              Delete
            </span>
          </div>
        </SelectItem>
      ))}
    </Select>
  );
};

// Define prop types for better type checking
FilterSelect.propTypes = {
  filterNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleDropdownChange: PropTypes.func.isRequired,
  deleteFilter: PropTypes.func.isRequired,
};

export default FilterSelect;
