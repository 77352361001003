import { useEffect, useState } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, ComposedChart, Legend } from 'recharts';
import getDateString from '../Utils';
import PulsingDot from '../NewDashboardComponents/PulsingDotComponent';

const NewCallPutChart = () => {
    const [data, setData] = useState([]);
    const [dataRange, setDataRange] = useState({ start: 0, end: data.length - 1 });

    useEffect(() => {
        const dateString = "calls_and_puts" + getDateString();
        const q = query(collection(firestore, dateString), orderBy('unixTimestamp', 'asc'));
        // console.log('Getting chart data:', q);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            // console.log('Getting chart unsub:', q);
            const chartData = snapshot.docs.map((doc) => {
                const { unixTimestamp, puts, calls } = doc.data();
                if (puts === 0 || calls === 0 || puts === undefined || calls === undefined) {
                    return null;
                }
                const putCallRatio = puts / calls;
                return { timestamp: unixTimestamp, putCallRatio, puts, calls };
            }).filter((data) => data !== null);

            const movingAverageData = chartData.map((entry, index) => {
                if (index < 14) {
                    return { ...entry, movingAverage: null };
                }
                const previousEntries = chartData.slice(index - 14, index + 1);
                const sum = previousEntries.reduce((acc, cur) => acc + cur.putCallRatio, 0);
                const movingAverage = sum / 15;
                return { ...entry, movingAverage };
            });

            const lastTimestamp = movingAverageData.length > 0 ? movingAverageData[movingAverageData.length - 1].timestamp : 0;
            const additionalPoints = 10;
            const timestampIncrement = 60;

            for (let i = 1; i <= additionalPoints; i++) {
                movingAverageData.push({
                    timestamp: lastTimestamp + timestampIncrement * i,
                    putCallRatio: null,
                    movingAverage: null,
                    puts: null,
                    calls: null,
                });
            }

            setData(movingAverageData);
            setDataRange({ start: 0, end: movingAverageData.length - 1 });
        });

        return () => unsubscribe();
    }, []);
    // Calculate the minimum and maximum values of putCallRatio
    const minRatio = Math.min(...data.map((entry) => entry.putCallRatio));
    const maxRatio = Math.max(...data.map((entry) => entry.putCallRatio));

    // Set the y-axis domain based on the minimum and maximum values
    const yAxisDomain = [minRatio * 0.9, maxRatio * 1.1];
    // Calculate the maximum values of puts and calls
    const maxPuts = Math.max(...data.map((entry) => entry.puts || 0));
    const maxCalls = Math.max(...data.map((entry) => entry.calls || 0));
    const maxPutsCalls = Math.max(maxPuts, maxCalls);

    // Set the domain for the right y-axis (increase max value by 10%)
    const yAxisRightDomain = [0, maxPutsCalls * 4];

    console.log(`Right domain details: ${yAxisRightDomain}`);

    // Format the timestamp to display only the time in "HH:mm" format in EST timezone
    const formatTimestamp = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const options = { timeZone: 'America/New_York', hour: 'numeric', minute: 'numeric', hour12: false };
        return date.toLocaleString('en-US', options);
    };
    // Format the Y-axis tick values to round to 2 decimal places
    const formatYAxisTick = (value) => {
        return value.toFixed(2);
    };
    const visibleData = data.slice(dataRange.start, dataRange.end + 1);
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // Find each data series in the tooltip payload by key
            const ratioValueObj = payload.find((p) => p.dataKey === 'putCallRatio');
            const movingAverageObj = payload.find((p) => p.dataKey === 'movingAverage');
            const putsValueObj = payload.find((p) => p.dataKey === 'puts');
            const callsValueObj = payload.find((p) => p.dataKey === 'calls');
    
            // Extract the actual numeric values safely
            const ratioValue = ratioValueObj?.value;
            const movingAverage = movingAverageObj?.value;
            const putsValue = putsValueObj?.value;
            const callsValue = callsValueObj?.value;
    
            return (
                <div
                    style={{
                        backgroundColor: '#363636CC',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        color: 'white',
                        fontSize: '14px',
                        textAlign: 'center',
                    }}
                >
                    {/* Only display if the value is not undefined */}
                    {ratioValue !== undefined && (
                        <p style={{ fontWeight: 550 }}>{`${ratioValue.toFixed(2)}`}</p>
                    )}
                    {movingAverage !== undefined && (
                        <p style={{ fontWeight: 550 }}>{`Moving Avg: ${movingAverage.toFixed(2)}`}</p>
                    )}
                    {putsValue !== undefined && (
                        <p style={{ fontWeight: 550 }}>{`Puts ${putsValue.toFixed(0)}`}</p>
                    )}
                    {callsValue !== undefined && (
                        <p style={{ fontWeight: 550 }}>{`Calls ${callsValue.toFixed(0)}`}</p>
                    )}
                    <p>{`${formatTimestamp(label)}`} EST</p>
                </div>
            );
        }
        return null;
    };
    

    return (
        <div
            style={{
                backgroundColor: 'black',
                borderRadius: '10px',
                paddingTop: '20px',
                paddingRight: '20px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                // width: '100%',
                height: '350px',
                flex: 1,
                boxShadow: `0 8px 15px rgb(0, 0, 0, 0.5)`,
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginLeft: '20px' }}>
                <h1 style={{
                    color: 'white', margin: 0,
                    fontWeight: 'bold', marginRight: '5px'
                }}>
                    Market Put/Call Tracker
                </h1>
                <PulsingDot />
            </div>
            <ResponsiveContainer width="100%" height="87.5%">
                <ComposedChart
                    data={visibleData}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                >
                    <XAxis dataKey="timestamp" tickFormatter={formatTimestamp} tickCount={5} />
                    <YAxis
                        domain={yAxisDomain}
                        tickFormatter={formatYAxisTick}
                        yAxisId="left"
                    />
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        domain={yAxisRightDomain}
                    />

                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="putCallRatio"
                        name="Put/Call Ratio"
                        stroke="#0088ff"
                        dot={false}
                        strokeWidth={1.75}
                        isAnimationActive={false}
                        yAxisId="left"
                    />
                    <Line
                        type="monotone"
                        dataKey="movingAverage"
                        name="Moving Average"
                        stroke="#c2fc03"
                        dot={false}
                        strokeWidth={1.75}
                        isAnimationActive={false}
                        yAxisId="left"
                    />
                    <Bar
                        dataKey="puts"
                        name="Puts"
                        stackId="a"
                        fill="#cc3753"
                        yAxisId="right"
                        barGap={0}
                    />
                    <Bar
                        dataKey="calls"
                        name="Calls"
                        stackId="a"
                        fill="#20d46b"
                        yAxisId="right"
                        barGap={0}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default NewCallPutChart;