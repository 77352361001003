import { useMediaQuery } from "react-responsive";
import Sidebar from "../Sidebar";
import { getGreeting } from "../Utils";
import BorderMagicButton from "../components/ui/BorderMagicButton";
import HotStocks from "./HotStocks";
import MarketOverviewHomeComponent from "./MarketOverviewHomeComponent";
import NewCallPutChart from "./NewCallPutChart";
import VolumeSpikeComponent from "./VolumeSpikeComponent";
import { Helmet } from 'react-helmet';
import BorderMagicImageButton from "../components/BorderMagicImageButton";

function HomePage() {
  const currentDate = new Date();
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);

  const handleDiscordClick = () => {
    window.open('https://discord.gg/mZ6JwbsRH7', '_blank');
  };

  const handleYoutubeClick = () => {
    window.open('https://www.youtube.com/watch?v=wKKzwk802EA', '_blank');
  };
  const isPhoneSize = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'row', background: '#1a1b20' }}>
      <Sidebar activeItem="home" />
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Bullflow dashboard" />
      </Helmet>

      {/* Conditional rendering based on screen size */}
      {isPhoneSize ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', background: '#333', borderRadius: '10px', margin: '20px' }}>
          <div style={{ textAlign: 'center' }}>
            <p style={{ color: '#f0f0f0', fontSize: '1.2em', margin: '0 0 20px 0' }}>
              Download the Bullflow app now for the best experience!
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
              <a href="https://play.google.com/store/apps/details?id=io.bullflow.bullflow" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://www.aps.edu/students-parents/images/downloadOnTheGooglePlayStoreBadge.png/image"
                  alt="Download on Google Play"
                  style={{ width: '150px', height: 'auto' }}
                />
              </a>
              <a href="https://apps.apple.com/us/app/bullflow-live-options-flow/id6741772392" target="_blank" rel="noopener noreferrer">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvzJASvP_DUtrXr3e-pFLajZYQ8NUOP-g46g&s"
                  alt="Download on App Store"
                  style={{ width: '150px', height: 'auto' }}
                />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ flex: 1, height: '100vh', overflowY: 'auto', scrollBehavior: 'smooth' }}>
          <div style={{ marginTop: '40px', marginRight: '60px', marginLeft: '60px', marginBottom: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#1a1b20' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px',
                padding: 0,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0px' }}>
                <h1 style={{ color: 'white', margin: 0, fontWeight: 'bold', fontSize: '1.35em' }}>
                  {getGreeting()}
                </h1>
                <h2 style={{ color: '#A9A9A9', margin: 0, fontWeight: 550, fontSize: '0.8em' }}>
                  {formattedDate}
                </h2>
              </div>
              {/* Group the YouTube and Discord buttons together */}
              <div style={{ display: 'flex', gap: '10px' }}>
                <BorderMagicButton text="Latest Video" onClick={handleYoutubeClick} />
                <BorderMagicButton onClick={handleDiscordClick} />
              </div>
            </div>


            <div style={{ width: '100%', height: '0.5px', backgroundColor: '#2b2b2b', padding: '0.5px' }}></div>

            <div style={{ width: '100%', marginTop: '40px', marginBottom: '10px', display: 'flex', flexDirection: 'column', gap: '20px', padding: '0 0px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '40px', marginBottom: '40px' }}>
                <MarketOverviewHomeComponent />
                <NewCallPutChart />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', gap: '40px', marginBottom: '30px' }}>
                <VolumeSpikeComponent />
                <HotStocks />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomePage;