import { Flame } from 'lucide-react';
import { useState } from 'react';

const TrendingAlertsIconButton = ({ onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isToggled, setIsToggled] = useState(false); // New state to track toggle

    const iconButtonStyle = {
        backgroundColor: isHovered ? '#292a30' : '#1f2026',
        border: 'none',
        padding: '10px',
        borderRadius: '90px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color 0.3s ease',
        transition: 'box-shadow 0.3s ease',
        boxShadow: isHovered ? '0px 4px 6px rgba(0, 0, 0, 0.5)' : '0px 2px 3px rgba(0, 0, 0, 0.3)',
    };

    // Modify this function to toggle the `isToggled` state
    const handleToggle = () => {
        setIsToggled(!isToggled);
        if (onClick) onClick(); // Call the original onClick prop, if provided
    };

    return (
        <button
            style={iconButtonStyle}
            className="icon-button"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleToggle}
        >
            {isToggled ? <Flame size={18} strokeWidth={1.25} /> : <Flame size={18} strokeWidth={1.25} />}
        </button>
    );
};

export default TrendingAlertsIconButton;