import React, { useState, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { convertOptionsSymbol, formatPremium, getBullflowColorPrefCB, optionColorShouldBeGreenOnlyType, parseOptionInfo, timeAgo } from '../Utils';
import { collection, query, onSnapshot, limit } from 'firebase/firestore';
import { firestore } from '../firebase';

const bullflowColorPref = getBullflowColorPrefCB();
const BULLFLOW_GREEN = "#20d46b";
const BULLFLOW_RED = "#cc3753";
const BULLFLOW_OPACITY_GREEN = "rgba(2, 212, 44, 0.15)";
const BULLFLOW_OPACITY_RED = "rgba(250, 0, 67, 0.15)";
const CB_OPACITY_GREEN = "rgba(44, 140, 90, 0.15)";
const CB_OPACITY_RED = "rgba(211, 84, 0, 0.15)";
const CB_GREEN = "#2c8c5a";
const CB_RED = "#d35400";
const greenType = bullflowColorPref ? CB_GREEN : BULLFLOW_GREEN;
const redType = bullflowColorPref ? CB_RED : BULLFLOW_RED;

const StyledText = ({
  text,
  optionColorShouldBeGreen,
  fontSize = '12px',
}) => {
  const wrapperStyle = {
    borderRadius: '5px',
    color: '#fff',
    backgroundColor: optionColorShouldBeGreen ? BULLFLOW_OPACITY_GREEN : BULLFLOW_OPACITY_RED,
    borderColor: optionColorShouldBeGreen ? greenType : redType,
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: '5px',
    lineHeight: '15px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize,
  };

  return <span style={wrapperStyle}>{convertOptionsSymbol(text)}</span>;
};

function ListItem({ index, style, data }) {
  const alert = data[index];
  const optionInfo = parseOptionInfo(alert.symbol);
  const ot = optionInfo["Option Type"];
  const shouldBeGreen = optionColorShouldBeGreenOnlyType(ot);

  return (
    <div style={{ ...style, padding: '8px', boxSizing: 'border-box' }}>
      <div
        style={{
          border: 'none',
          borderRadius: 6,
          padding: '12px',
          backgroundColor: '#000',
          color: '#fff',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '8px',
          }}
        >
          <span style={{ fontWeight: 600 }}>{alert.title}</span>
          <span style={{ color: '#ddd' }}>{timeAgo(alert.timestamp)}</span>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '12px',
          }}
        >
          <StyledText
            text={alert.symbol}
            optionColorShouldBeGreen={shouldBeGreen}
          />
          <span
            style={{
              color: '#fff',
              width: '80px',
              textAlign: 'right',
              marginLeft: '10px',
            }}
          >
            {formatPremium(alert.premium)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default function AlertsScrollingVirtualizedList({ displayed }) {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    console.log(`AlertsScrollingVirtualizedList displayed: ${displayed}`);
    let unsubscribe = null;

    if (displayed) {
      // Create a query for the "_flow_alerts" collection
      const q = query(collection(firestore, "_flow_alerts"), limit(10));
      // Set up real-time listener
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        console.log(`AlertsScrollingVirtualizedList received data: ${querySnapshot.size}`);
        const alertsData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(alert => alert.premium !== undefined && alert.premium !== null);
        setAlerts(alertsData);
      }, (error) => {
        console.error("Error fetching alerts:", error);
      });
    }

    // Cleanup subscription when component unmounts or displayed becomes false
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [displayed]); // Add displayed as a dependency

  // Only render the list when displayed is true
  if (!displayed) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '12px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ 
        marginBottom: '8px',
        paddingLeft: '12px',
        paddingTop: '12px',
      }}>
        <h2
          style={{
            textAlign: 'left',
            fontWeight: 'bold',
            color: '#fff',
            margin: 0,
          }}
        >
          Alerts
        </h2>
        <p
          style={{
            textAlign: 'left',
            color: '#fff',
            margin: 0,
            fontSize: '14px',
          }}
        >
          Custom Alerts, Bullflow algo alerts and trade alerts appear here
        </p>
      </div>

      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            itemCount={alerts.length}
            itemSize={90}
            itemData={alerts}
          >
            {ListItem}
          </List>
        )}
      </AutoSizer>
    </div>
  );
}