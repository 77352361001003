import { auth } from './firebase';
import { firestore } from './firebase';
import { GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, sendEmailVerification, getAuth } from "firebase/auth";
import { collection, doc, getDoc, setDoc, getDocs, query, where } from 'firebase/firestore';
import googleLogoPath from './g_icon.svg';
import { useNavigate } from 'react-router-dom';
import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';
import xLogoWhite from './x-logo-white.png';
import { useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider, Container, createTheme, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { TwitterAuthProvider } from "firebase/auth";
import { Form, Input, Button, Alert } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const stripePromise = loadStripe('pk_live_51NNbxVJBifvfVJGR8LZPTzcjkTFjRR9Gjp7pX2zWBOsPLCJt1mAhhAf82VQOzbrk8zH2Bi4kP2QohPwHzk8Rrbh700sfre53E6');

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFD700', // Change this as per your requirement
                    },
                },
                input: {
                    color: 'white',
                    height: '1.5em',
                    padding: '10px 12px',
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "grey",
                    "&.Mui-focused": {
                        color: "white" // Change this as per your requirement
                    },
                    "&:hover": {
                        color: "white" // Change this as per your requirement
                    },
                }
            }
        }
    }
});

// Function for handling monthly checkout
async function handleMonthlyCheckout(userEmail) {
    window.gtag('event', 'Monthly Checkout from sign up screen', {
        'event_category': 'Button',
        'event_label': 'Monthly Checkout from sign up screen',
        'value': 1
    });
    try {
        const createCheckoutSession = httpsCallable(functions, 'createCheckoutSessionMonthly');
        const session = await createCheckoutSession({ email: userEmail, subscriptionType: "monthly", toltReferral: window.tolt_referral });
        const stripe = await stripePromise;

        const { error } = await stripe.redirectToCheckout({
            sessionId: session.data.sessionId,
        });

        if (error) {
            console.error("Checkout error 81121");
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.error("Checkout process failed:", error);
        return false;
    }
}

// Function for handling yearly checkout
async function handleYearlyCheckout(userEmail) {
    window.gtag('event', 'Yearly Checkout from sign up screen', {
        'event_category': 'Button',
        'event_label': 'Yearly Checkout from sign up screen',
        'value': 1
    });
    try {
        const createCheckoutSession = httpsCallable(functions, 'createCheckoutSessionYearly');
        const session = await createCheckoutSession({ email: userEmail, subscriptionType: "yearly", toltReferral: window.tolt_referral });
        const stripe = await stripePromise;

        const { error } = await stripe.redirectToCheckout({
            sessionId: session.data.sessionId,
        });

        if (error) {
            console.error("Checkout error 81121");
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.error("Checkout process failed:", error);
        return false;
    }
}


const SignUpScreen = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [showEmailForm, setShowEmailForm] = useState(false); // New state to toggle the email form
    const [emailSent, setEmailSent] = useState(false);
    const [signUpError, setSignUpError] = useState('');

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 550);
        return () => {
            clearTimeout(timer);
        };
    });

    const handleGoogleSignup = async () => {
        // setLoading(true);
        window.gtag('event', 'Sign up with Google Button', {
            'event_category': 'Button',
            'event_label': 'Sign up with Google',
            'value': 1
        });
        const provider = new GoogleAuthProvider();
        const subscriptionFlow = searchParams.get('subscriptionFlow'); // Retrieve the 'subscriptionFlow' parameter

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            performLoginCheckProcess(subscriptionFlow, user.email);

        } catch (error) {
            // console.error("Error signing up with Google: ", error);
            setLoading(false);
            console.error("Error code 91733");
        }
    };


    const handleTwitterSignup = async () => {
        // setLoading(true);
        const provider = new TwitterAuthProvider();
        window.gtag('event', 'Sign up with X Button', {
            'event_category': 'Button',
            'event_label': 'Sign up with X',
            'value': 1
        });
        try {
            const result = await signInWithPopup(auth, provider);
            const subscriptionFlow = searchParams.get('subscriptionFlow'); // Retrieve the 'subscriptionFlow' parameter
            // Handle the sign-in result here (e.g., update user profile, navigate)
            const email = result.user.email;
            if (email) {
                console.log("Twitter sign-in successful " + email);
                performLoginCheckProcess(subscriptionFlow, email);
            } else {
                console.log("Twitter sign-in successful, no email returned");
                setLoading(false);
            }
        } catch (error) {
            console.error("Error signing up with Twitter:", error);
            setLoading(false);
        }
    };

    const performLoginCheckProcess = async (subscriptionFlow, email) => {
        console.log("Performing login check");
        const userDoc = await getDoc(doc(collection(firestore, "users"), email));

        // Create a query against the collection
        const paymentsQuery = query(collection(firestore, "payments"), where("user", "==", email));

        // Execute the query
        const paymentQuerySnapshot = await getDocs(paymentsQuery);

        // Checks if any matching documents were found
        const paymentDocExists = !paymentQuerySnapshot.empty;

        if (userDoc.exists() && paymentDocExists) { // checks if the user exists in both collections
            // Get the first document from the query snapshot
            const paymentDoc = paymentQuerySnapshot.docs[0];
            const subscriptionType = paymentDoc.data().subscriptionType;

            if (subscriptionType === 'monthly' || subscriptionType === 'yearly') {
                // Handle subscription type
                // console.log(`User has a ${subscriptionType} subscription.`);
                navigate('/app');
            } else {
                navigate('/pricing');
                // console.log('User does not have a monthly or yearly subscription.');
            }

        } else {
            setLoading(true);
            await setDoc(doc(collection(firestore, "users"), email), {
                email: email,
            });
            if (subscriptionFlow === "monthly") {
                handleMonthlyCheckout(email);
            } else if (subscriptionFlow === "yearly") {
                handleYearlyCheckout(email);
            } else {
                console.error("There was a problem reading the params");
                navigate('/pricing');
            }
        }
    }
    const handleEmailSignUp = async (values) => {
        const { email, password } = values;
        if (email.endsWith('@privaterelay.appleid.com')) {
            setSignUpError("There was a problem signing you up, please use a different email address or sign in method.");
            return;
        }
        const auth = getAuth();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            setEmailSent(true);
            const actionCodeSettings = {
                url: `https://bullflow.io/pricing?checkout=${searchParams.get('subscriptionFlow') ? searchParams.get('subscriptionFlow') : ''}`,
                handleCodeInApp: true,
            };

            await sendEmailVerification(user, actionCodeSettings);
            console.log('Verification email sent.');
        } catch (error) {
            console.error("Error signing up with email and password:", error);
            if (error.message) {
                // Replace the firebase in error.message with a more user-friendly message
                setSignUpError(error.message.replace('Firebase', 'Bullflow'));
            } else {
                setSignUpError("An error occurred during sign in. Please try again later.");
            }
        }
    };

    const toggleEmailForm = () => {
        setShowEmailForm(!showEmailForm);
    };

    const renderEmailForm = () => {
        if (emailSent) {
            return <div style={{ marginTop: '30px', animation: 'fadeInUp 1.0s ease-out forwards' }}>
                Check your email for the verification link to complete your sign-up process.
            </div>;
        } else if (showEmailForm) {
            return (
                <div style={{ animation: 'fadeInUp 1.0s ease-out forwards', marginTop: '30px' }}>
                    <Form onFinish={handleEmailSignUp} style={{ maxWidth: '300px', margin: 'auto' }}>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input placeholder="Email" type="email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password
                                placeholder="Password"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#FFD700', borderColor: '#FFD700', color: '#040129' }}>
                                Sign Up
                            </Button>
                        </Form.Item>
                        {/* Conditionally render the error message */}
                        {signUpError && (
                            <div style={{ marginTop: '10px' }}>
                                <Alert message={signUpError} type="error" showIcon />
                            </div>
                        )}
                    </Form>
                </div>
            );
        }
        return null;
    };

    return (
        <ThemeProvider theme={theme}>
            <style>
                {`
                    @keyframes fadeInUp {
                        from {
                            opacity: 0;
                            transform: translateY(20px);
                        }
                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                `}
            </style>
            <Helmet>
                <title>Sign up</title>
                <meta name="description" content="Bullflow Sign up" />
                <meta name="keywords" content="Sign up to Bullflow unusual option flow platform" />
            </Helmet>
            <Container component="main"
                maxWidth="xs"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '70vh',
                    marginTop: '100px',
                    marginBottom: '180px',
                }}>
                <div style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    opacity: 0, // Set initial opacity to 0
                    transform: 'translateY(20px)', // Initial transform state
                    animation: 'fadeInUp 1.0s ease-out forwards' // Set animation fill mode to 'forwards'

                }}>
                    <p style={{
                        margin: '35px 0',
                        whiteSpace: 'nowrap',
                        fontSize: '1.5em',
                        fontWeight:550,
                        background: 'linear-gradient(to right, #8593ff, #fff, #fce774)', 
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        display: 'inline',
                    }}>
                        Let's Get Started
                    </p>

                </div>

                <div style={{
                    display: 'flex', justifyContent: 'center', flexDirection: 'column',
                }}>
                    {/* Add margin top for spacing */}
                    <button
                        onClick={handleGoogleSignup}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',  // Transparent background
                            color: '#fff',
                            padding: '10px 20px',
                            border: '0.5px solid #4285F4',       // Thin white border
                            borderRadius: '50px',            // Pill-shaped border radius
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            outline: 'none',
                            textShadow: '0 -1px rgba(0, 0, 0, 0.25)',
                            fontFamily: 'Arial, sans-serif',
                            height: '45px',
                            opacity: 0, // Set initial opacity to 0
                            transform: 'translateY(20px)', // Initial transform state
                            animation: 'fadeInUp 1.0s ease-out 0.15s forwards' // Set animation fill mode to 'forwards'
                        }}
                    >
                        <img
                            src={googleLogoPath}
                            alt="Google Logo"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '10px',
                                backgroundColor: '#fff',
                                padding: '2px',
                                borderRadius: '2px',
                            }}
                        />
                        Continue with Google
                    </button>
                    <button
                        onClick={handleTwitterSignup}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',  // Transparent background
                            color: '#fff',
                            padding: '10px 20px',
                            border: '0.5px solid #a9afb0',       // Thin white border
                            borderRadius: '50px',            // Pill-shaped border radius
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            marginTop: '20px',
                            outline: 'none',
                            textShadow: '0 -1px rgba(0, 0, 0, 0.25)',
                            fontFamily: 'Arial, sans-serif',
                            height: '45px',
                            opacity: 0,                      // Set initial opacity to 0
                            transform: 'translateY(20px)',   // Initial transform state
                            animation: 'fadeInUp 1.0s ease-out 0.3s forwards' // Animation

                        }}
                        type="button"
                    >
                        Continue with
                        <img
                            src={xLogoWhite}
                            alt="Logo"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '10px', // Add some space between the text and the logo
                            }}
                        />
                    </button>

                </div>
                {!showEmailForm && ( /* Conditionally render the buttons */
                    <div>
                        {/* Your existing sign-up buttons here */}
                        <div onClick={toggleEmailForm} style={{
                            cursor: 'pointer',
                            opacity: 0,
                            animation: 'fadeInUp 1.0s ease-out 0.6s forwards', fontWeight: 550, fontSize: '0.9em', textAlign: 'center', marginTop: '20px'
                        }}>
                            Sign up with email
                        </div>
                    </div>
                )}
                {renderEmailForm()}
            </Container>
        </ThemeProvider>
    );
};

export default SignUpScreen;
