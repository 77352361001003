// UpdatedCallPutBar.jsx
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatNumber, formatNumberWithCommas } from "../../Utils";

// Define your color constants
const BULLFLOW_OPACITY_GREEN = "rgba(32, 212, 107, 0.2)"; // Semi-transparent green for puts
const BULLFLOW_GREEN = "#20d46b"; // Solid green
const BULLFLOW_OPACITY_RED = "rgba(204, 55, 83, 0.2)"; // Semi-transparent red for calls
const BULLFLOW_RED = "#cc3753"; // Solid red
const BULLFLOW_ORANGE = "#FFA500"; // Solid orange for borders and glow
const LABEL_GREY = "#888888"; // Grey color for labels
const PREMIUM_GREY = "#AAAAAA"; // Slightly lighter grey for premiums
const TEXT_WHITE = "#FFFFFF"; // White color for default text
const FLASH_GREEN = "#20d46b"; // Green color for call flash
const FLASH_RED = "#cc3753"; // Red color for put flash

const UpdatedCallPutBar = ({
    initialPutValue = 30,
    initialCallValue = 70,
    initialPutPremium = 1,
    initialCallPremium = 1,
    height = "12px",
    borderRadius = "4px",
    transitionDuration = "500ms",
}) => {
    // State variables for dynamic values
    // const [putValue, setPutValue] = useState(initialPutValue);
    // const [callValue, setCallValue] = useState(initialCallValue);
    // const [putPremium, setPutPremium] = useState(initialPutPremium);
    // const [callPremium, setCallPremium] = useState(initialCallPremium);

    // State variables for flashing effect
    const [isPutFlashing, setIsPutFlashing] = useState(false);
    const [isCallFlashing, setIsCallFlashing] = useState(false);

    // Calculate total to compute percentages
    const total = initialPutValue + initialCallValue;
    const normalizedPut = total > 0 ? (initialPutValue / total) * 100 : 0;
    const normalizedCall = total > 0 ? (initialCallValue / total) * 100 : 0;

    return (
        <div className="w-full flex flex-col items-center">
            {/* Labels Container */}
            <div className="w-full flex justify-between mb-1 px-0">
                {/* Puts Label */}
                <div className="flex flex-col items-start">
                    <span style={{ color: LABEL_GREY, fontSize: '0.8rem' }}>
                        Puts
                    </span>
                    <span
                        style={{
                            color: isPutFlashing ? FLASH_RED : TEXT_WHITE,
                            fontWeight: 'bold',
                            fontSize: '0.9rem',
                            transition: 'color 0.3s ease-in-out',
                        }}
                    >
                        {initialPutValue}
                    </span>
                    <span style={{ color: PREMIUM_GREY, fontSize: '0.8rem', marginTop: '0px' }}>
                        ${formatNumber(initialPutPremium)}
                    </span>
                </div>
                {/* Calls Label */}
                <div className="flex flex-col items-end">
                    <span style={{ color: LABEL_GREY, fontSize: '0.8rem' }}>
                        Calls
                    </span>
                    <span
                        style={{
                            color: isCallFlashing ? FLASH_GREEN : TEXT_WHITE,
                            fontWeight: 'bold',
                            fontSize: '0.9rem',
                            transition: 'color 0.3s ease-in-out',
                        }}
                    >
                        {initialCallValue}
                    </span>
                    <span style={{ color: PREMIUM_GREY, fontSize: '0.8rem', marginTop: '0px' }}>
                        ${formatNumber(initialCallPremium)}
                    </span>
                </div>
            </div>

            {/* Bar Container */}
            <div
                className="w-full flex overflow-hidden"
                style={{
                    height: height,
                    borderRadius: borderRadius,
                    backgroundColor: "#333",
                }}
            >
                {/* Put Segment */}
                <div
                    className="transition-all ease-in-out"
                    style={{
                        width: `${normalizedPut}%`,
                        backgroundColor: BULLFLOW_OPACITY_RED,
                        borderTopLeftRadius: borderRadius,
                        borderBottomLeftRadius: borderRadius,
                        borderTop: `1px solid ${BULLFLOW_RED}`,
                        borderBottom: `1px solid ${BULLFLOW_RED}`,
                        borderLeft: `1px solid ${BULLFLOW_RED}`,
                        borderRight: `1px solid ${BULLFLOW_ORANGE}`,
                        boxShadow: `2px 0 4px ${BULLFLOW_ORANGE}`,
                        transitionDuration: transitionDuration,
                    }}
                ></div>

                {/* Call Segment */}
                <div
                    className="transition-all ease-in-out"
                    style={{
                        width: `${normalizedCall}%`,
                        backgroundColor: BULLFLOW_OPACITY_GREEN,
                        borderTopRightRadius: borderRadius,
                        borderBottomRightRadius: borderRadius,
                        borderTop: `1px solid ${BULLFLOW_GREEN}`,
                        borderBottom: `1px solid ${BULLFLOW_GREEN}`,
                        borderRight: `1px solid ${BULLFLOW_GREEN}`,
                        borderLeft: `1px solid ${BULLFLOW_ORANGE}`,
                        boxShadow: `-2px 0 4px ${BULLFLOW_ORANGE}`,
                        transitionDuration: transitionDuration,
                    }}
                ></div>
            </div>
        </div>
    );

};

UpdatedCallPutBar.propTypes = {
    initialPutValue: PropTypes.number,
    initialCallValue: PropTypes.number,
    initialPutPremium: PropTypes.number,
    initialCallPremium: PropTypes.number,
    height: PropTypes.string,
    borderRadius: PropTypes.string,
    transitionDuration: PropTypes.string,
};

export default UpdatedCallPutBar;
