import React from 'react';

const GlowingBall = () => {
  return (
    <div className="relative w-[38px] h-[38px] aspect-square rounded-full bg-gray-800/50 backdrop-blur-md overflow-hidden">
      <div className="absolute inset-0 flex justify-center items-center">
        {/* Gold orb */}
        <div className="relative w-full h-full animate-spin-slow">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-6 h-6 bg-yellow-500 rounded-full blur-sm shadow-lg opacity-90" />
        </div>
        {/* Lighter Blue orb */}
        <div className="relative w-full h-full animate-spin-slower" style={{ animationDelay: '0.2s' }}>
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-5 h-5 bg-sky-300 rounded-full blur-sm shadow-lg opacity-90" />
        </div>
        {/* White orb */}
        <div className="relative w-full h-full animate-spin-slower" style={{ animationDelay: '0.4s' }}>
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-7 h-7 bg-white rounded-full blur-sm shadow-lg opacity-90" />
        </div>
      </div>
    </div>
  );
};

export default GlowingBall;
