import { useState, useEffect } from 'react';
import { Input, Slider } from 'antd';
import './bullflowvolumeslider.css';

// Define how high the percentage can go
// Adjust this as needed (e.g., maybe 200 or 500)
const MAX_PERCENT = 100;

function VolumeOfInterestFilter({ 
  minVolumePercentProp, 
  maxVolumePercentProp, 
  handleFilterChange 
}) {
  const [minVolumePercent, setMinVolumePercent] = useState(minVolumePercentProp);
  const [maxVolumePercent, setMaxVolumePercent] = useState(maxVolumePercentProp);

  // Sync local state with incoming props
  useEffect(() => {
    setMinVolumePercent(minVolumePercentProp);
  }, [minVolumePercentProp]);

  useEffect(() => {
    setMaxVolumePercent(maxVolumePercentProp);
  }, [maxVolumePercentProp]);

  // Handle slider changes for both min and max
  const onChangeSlider = (values) => {
    let [newMin, newMax] = values;

    // Clamp if needed
    if (newMin < 0) newMin = 0;
    if (newMax > MAX_PERCENT) newMax = MAX_PERCENT;

    setMinVolumePercent(newMin);
    setMaxVolumePercent(newMax);

    // Pass both values up to parent component
    handleFilterChange({ min: newMin, max: newMax });
  };

  // Handle min input field changes
  const onChangeMinInput = (e) => {
    const newMinValue = Number(e.target.value);

    if (newMinValue >= 0 && newMinValue <= MAX_PERCENT) {
      setMinVolumePercent(newMinValue);

      // Ensure max stays >= min
      const adjustedMax = Math.max(maxVolumePercent, newMinValue);
      setMaxVolumePercent(adjustedMax);

      handleFilterChange({ min: newMinValue, max: adjustedMax });
    }
  };

  // Handle max input field changes
  const onChangeMaxInput = (e) => {
    const newMaxValue = Number(e.target.value);

    if (newMaxValue >= 0 && newMaxValue <= MAX_PERCENT) {
      setMaxVolumePercent(newMaxValue);

      // Ensure min stays <= max
      const adjustedMin = Math.min(minVolumePercent, newMaxValue);
      setMinVolumePercent(adjustedMin);

      handleFilterChange({ min: adjustedMin, max: newMaxValue });
    }
  };

  return (
    <div style={{ width: '90%', marginTop: '0px' }}>
      <p style={{ fontWeight: 600, fontSize: '0.8em', marginBottom: '10px' }}>
        Volume % of OI
      </p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          marginBottom: '0px'
        }}
      >
        {/* Range slider for Volume % of OI */}
        <Slider
          range
          min={0}
          max={MAX_PERCENT}
          onChange={onChangeSlider}
          value={[minVolumePercent, maxVolumePercent]}
          style={{ flexGrow: 7.5, marginRight: '10px' }}
          tooltip={{
            formatter: (val) => `${val}%`,
            visible: true,
            zIndex: 15001
          }}
        />
      </div>
    </div>
  );
}

export default VolumeOfInterestFilter;
