import { useState, useEffect } from 'react';
import { Input, Slider } from 'antd';
import './bullflowvolumeslider.css';

const MAX_OI = 100000; // or whatever max OI you want


function OIFilter({ minOIProp, maxOIProp, handleFilterChange }) {
    // Store min and max in local state
    const [minOI, setMinOI] = useState(minOIProp);
    const [maxOI, setMaxOI] = useState(maxOIProp);
  
    // Whenever incoming props change, update local state
    useEffect(() => {
      setMinOI(minOIProp);
    }, [minOIProp]);
  
    useEffect(() => {
      setMaxOI(maxOIProp);
    }, [maxOIProp]);
  
    // Handle the slider change for both min and max
    const onChangeSlider = (values) => {
      let [newMin, newMax] = values;
  
      // Clamp values if needed
      if (newMin < 0) newMin = 0;
      if (newMax > MAX_OI) newMax = MAX_OI;
  
      setMinOI(newMin);
      setMaxOI(newMax);
  
      // Pass both values back up to parent
      handleFilterChange({ min: newMin, max: newMax });
    };
  
    // Handle changes to the min input field
    const onChangeMinInput = (e) => {
      const newMinValue = Number(e.target.value);
  
      // Validate input
      if (newMinValue >= 0 && newMinValue <= MAX_OI) {
        setMinOI(newMinValue);
  
        // Ensure max is still >= min
        const adjustedMax = Math.max(maxOI, newMinValue);
        setMaxOI(adjustedMax);
  
        // Pass both values to parent
        handleFilterChange({ min: newMinValue, max: adjustedMax });
      }
    };
  
    // Handle changes to the max input field
    const onChangeMaxInput = (e) => {
      const newMaxValue = Number(e.target.value);
  
      // Validate input
      if (newMaxValue >= 0 && newMaxValue <= MAX_OI) {
        setMaxOI(newMaxValue);
  
        // Ensure min is still <= max
        const adjustedMin = Math.min(minOI, newMaxValue);
        setMinOI(adjustedMin);
  
        // Pass both values to parent
        handleFilterChange({ min: adjustedMin, max: newMaxValue });
      }
    };
  
    return (
      <div style={{ width: '90%', marginTop: '0px' }}>
        <p style={{ fontWeight: 600, fontSize: '0.8em', marginBottom: '10px' }}>
          Open Interest
        </p>
  
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: '0px'
          }}
        >
          {/* Range slider for min OI and max OI */}
          <Slider
            range
            className='bfvolume-gradient-slider'
            min={0}
            max={MAX_OI}
            onChange={onChangeSlider}
            value={[minOI, maxOI]}
            style={{ flexGrow: 7.5, marginRight: '10px' }}
            tooltip={{
              formatter: val => `${val}`,
              visible: true,
              zIndex: 15001
            }}
          />
        </div>
      </div>
    );
  }
export default OIFilter;
