import { PricingShootingStarButton } from "./NewPagesComponents/PricingShootingStarButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { loadStripe } from '@stripe/stripe-js';
import { firestore, functions } from './firebase';
import { httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import { collection, query, getDocs, where } from "firebase/firestore";
import { CircularProgress } from "@mui/material";

const stripePromise = loadStripe('pk_live_51NNbxVJBifvfVJGR8LZPTzcjkTFjRR9Gjp7pX2zWBOsPLCJt1mAhhAf82VQOzbrk8zH2Bi4kP2QohPwHzk8Rrbh700sfre53E6');

const NewPricingScreen = () => {
  const [yearly, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isYearlyLoading, setIsYearlyLoading] = useState(false);
  const [isMonthlyLoading, setIsMonthlyLoading] = useState(false);
  const [fbUser, setFbUser] = useState(null);
  const [userEmail_, setUserEmail_] = useState('');
  const [checkingUser, setCheckingUser] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  // Helper function to open the Stripe customer portal using Bullflow API
  const redirectToStripePortal = async (customerId) => {
    try {
      const response = await fetch("https://api.bullflow.io/openStripePortal", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerId }),
      });
      const data = await response.json();
      if (data.url) {
        window.location.assign(data.url);
      } else {
        console.error("No URL returned from openStripePortal.");
      }
    } catch (error) {
      console.error("Error redirecting to Stripe portal:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCheckingUser(true);
        setFbUser(user);
        console.log("fb user set");

        if (!user.emailVerified) {
          setCheckingUser(false);
          console.log("User is not verified");
          return;
        }

        setUserEmail_(user.email);

        // Check if the user already has a subscription
        try {
          const paymentsCollection = collection(firestore, 'payments');
          const q = query(paymentsCollection, where('user', '==', user.email));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const paymentDoc = querySnapshot.docs[0];
            const data = paymentDoc.data();
            if (data.customerId && data.customerId.trim() !== "") {
              // console.log("Existing subscription found. Redirecting to Stripe portal.");
              // redirectToStripePortal(data.customerId);
              // return; // Stop further processing since we're redirecting
            } else {
              setCheckingUser(false);
            }
          } else {
            setCheckingUser(false);
          }
        } catch (error) {
          setCheckingUser(false);
          console.error("Error checking payments collection:", error);
        }
        setCheckingUser(false);

        // Process checkout search parameters if provided in the URL
        const searchParams = new URLSearchParams(window.location.search);
        const checkout = searchParams.get('checkout');
        if (checkout === 'monthly') {
          setChecked(false);
          console.log(`checkout monthly  ${user.email}`);
          handleMonthlyClick();
        } else if (checkout === 'yearly') {
          setChecked(true);
          console.log(`checkout yearly  ${user.email}`);
          handleYearlyClick();
        }
      } else {
        setFbUser(null);
        setUserEmail_('');
        setCheckingUser(false);
      }
    });

    return () => unsubscribe();
  }, [auth, firestore, navigate]);

  const handleYearlyClick = async () => {
    setIsYearlyLoading(true);
    setIsLoading(true);
    if (userEmail_ !== '') {
      window.gtag('event', 'Pricing screen yearly button', {
        'event_category': 'Button',
        'event_label': 'Pricing screen yearly button',
        'value': 1
      });
      // For a new subscription, process the checkout session.
      // const shouldRenew = await shouldUserRenew(fbUser);
      if (true) {
        const createCheckoutSession = httpsCallable(functions, 'createCheckoutSessionYearly');
        console.log(`Checking out for ${userEmail_} yearly`);
        const session = await createCheckoutSession({ email: userEmail_, subscriptionType: "yearly", toltReferral: window.tolt_referral });
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.data.sessionId,
        });

        if (error) {
          console.error("Checkout error 81121");
        }
      } else {
        navigate('/renew');
      }
    } else {
      window.gtag('event', 'Pricing screen get started, user not signed in', {
        'event_category': 'Button',
        'event_label': 'Pricing screen continue button',
        'value': 1
      });
      navigate("/SignUp?subscriptionFlow=yearly");
    }
  };

  const handleMonthlyClick = async () => {
    setIsMonthlyLoading(true);
    setIsLoading(true);
    if (userEmail_ !== '') {
      window.gtag('event', 'Pricing screen monthly button', {
        'event_category': 'Button',
        'event_label': 'Pricing screen monthly button',
        'value': 1
      });
      // For a new subscription, process the checkout session.
      // const shouldRenew = await shouldUserRenew(fbUser);
      if (true) {
        try {
          console.log(`Checking out for ${userEmail_} monthly`);
          const response = await fetch("https://api.bullflow.io/createCheckoutSessionMonthly", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
              // Add authentication headers here if needed.
            },
            body: JSON.stringify({
              email: userEmail_,
              subscriptionType: "monthly",
              toltReferral: window.tolt_referral
            })
          });
          if (!response.ok) {
            console.error("Failed to create checkout session");
            setIsMonthlyLoading(false);
            setIsLoading(false);
            return;
          }
          const session = await response.json();
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({
            sessionId: session.sessionId,
          });
          if (error) {
            console.error("Checkout error 81121", error);
          }
        } catch (error) {
          console.error("Error during checkout:", error);
        }
      } else {
        navigate('/renew');
      }
    } else {
      window.gtag('event', 'Pricing screen get started, user not signed in', {
        'event_category': 'Button',
        'event_label': 'Pricing screen continue button',
        'value': 1
      });
      navigate("/SignUp?subscriptionFlow=monthly");
    }
  };
  // if checking user, show loading spinner
  if (checkingUser) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
          padding: '2rem',
          marginBottom: '100px',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh',
        padding: '2rem',
        marginBottom: '100px',
      }}
    >
      <Helmet>
        <title>Pricing</title>
        <meta
          name="description"
          content="Get full access to Real-time Options Flow, without breaking the bank"
        />
        <meta name="keywords" content="Real-time options flow pricing" />
      </Helmet>
      <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          @media (max-width: 768px) {
            .pricing-container {
              flex-direction: column;
              align-items: center;
            }
            .pricing-option {
              margin: 2rem 0;
              width: 100%;
              max-width: 300px;
            }
            h1.pricing-title {
              white-space: normal !important;
            }
          }
        `}
      </style>
      <div style={{ textAlign: 'center', maxWidth: '100%' }}>
        <div
          style={{
            opacity: 0,
            display: 'inline-block',
            transform: 'translateY(20px)',
            animation: 'fadeInUp 0.85s ease-out forwards',
          }}
        >
          <h1
            className="pricing-title"
            style={{
              fontSize: '2.0em',
              fontWeight: 650,
              background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline',
            }}
          >
            Start Your Flow Trading Journey For Free
          </h1>
        </div>
        <p
          style={{
            opacity: 0,
            color: '#dedede',
            fontSize: '1.1em',
            fontWeight: 500,
            transform: 'translateY(20px)',
            animation: 'fadeInUp 0.85s ease-out 0.25s forwards',
            marginTop: '0.8rem',
          }}
        >
          Your first week is on us. Cancel anytime. <br />
          Includes full access to the Web Platform, Mobile App and Discord.
        </p>
        <div
          className="pricing-container"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            opacity: 0,
            transform: 'translateY(20px)',
            marginTop: '4rem',
            animation: 'fadeInUp 0.95s ease-out 0.35s forwards',
          }}
        >
          {/* Pricing Option 1 */}
          <div
            className="pricing-option"
            style={{
              backgroundColor: '#1a1b20',
              borderRadius: '8px',
              padding: '2rem',
              margin: '0 2rem',
              width: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2 style={{ fontWeight: 600, marginBottom: '0.4rem', fontSize: '1.4em' }}>
              Monthly
            </h2>
            <p
              style={{
                fontWeight: 500,
                fontSize: '0.8em',
                color: '#A9A9A9',
                marginBottom: '0.6rem',
              }}
            >
              Free for 1 week, then $29/month.
              <br /> Cancel anytime.
            </p>
            <h3
              style={{
                fontWeight: 600,
                fontSize: '1.4em',
                marginBottom: '1.2rem',
              }}
            >
              $29/month
            </h3>
            {isMonthlyLoading ? (
              <CircularProgress />
            ) : (
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: 'white',
                  border: 'white 1px solid',
                  padding: '0.6rem 1.6rem',
                  borderRadius: '40px',
                  fontWeight: 600,
                  fontSize: '0.9em',
                  cursor: 'pointer',
                }}
                onClick={() => handleMonthlyClick()}
              >
                Get Monthly Plan
              </button>
            )}
          </div>
          {/* Pricing Option 2 */}
          <div
            className="pricing-option"
            style={{
              backgroundColor: '#1a1b20',
              borderRadius: '16px',
              padding: '2rem',
              margin: '0 1rem',
              width: '300px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              boxShadow: '0px 0px 50px rgba(133, 147, 255, 0.7)',
            }}
          >
            <h2 style={{ fontWeight: 600, marginBottom: '0.4rem', fontSize: '1.4em' }}>
              Yearly
            </h2>
            <p
              style={{
                fontWeight: 500,
                fontSize: '0.8em',
                color: '#A9A9A9',
                marginBottom: '0.6rem',
              }}
            >
              Free for 1 week, then $300/year.
              <br />
              Cancel anytime.
            </p>
            <h3
              style={{
                fontWeight: 600,
                fontSize: '1.4em',
                marginBottom: '1.2rem',
                background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                display: 'inline',
              }}
            >
              $25/month
            </h3>
            {isYearlyLoading ? (
              <CircularProgress />
            ) : (
              <PricingShootingStarButton
                text="Get Yearly Plan"
                onClick={handleYearlyClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPricingScreen;
