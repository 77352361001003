import alertSound from './alert_sound_effect2.mp3';
import { addCustomAlert } from './NewDashboardComponents/APIRepository';
import { formatNumber } from './Utils';
const sectors = ["ALL", "Industrials", "Financial Services", "Consumer Cyclical", "Real Estate", "Basic Materials", "Other", "Technology", "Energy", "Consumer Defensive", "Communication Services", "Healthcare", "Utilities"]
const MAX_LAST_7_DAYS_BF_VOLUME = 500;
const MAX_PREV_DAY_BF_VOLUME = 250;
const MAX_PREVIOUS_VOLUME = 1000000;
const MAX_CURRENT_VOLUME = 1000000;
const MAX_OI = 1000000;

const checkAlertsWithTrade = (trade, today, secondsCalc, alertDict, isSoundOn) => {
    // //console.log(`Alert dict: ${JSON.stringify(alertDict, null, 2)}`);
    let yearPrefix = "20";
    for (const alertName in alertDict) {
        // console.log("HELLO?");
        if (!alertDict.hasOwnProperty(alertName)) {
            console.log("error 331282");
            continue;
        }

        const alert_ = alertDict[alertName];
        // Check if every tag in the alert is in the trades tags

        if (alert_.activeTags && alert_.activeTags.length > 0) {
            let tagsCheck = alert_.activeTags.every(tag => trade.tg.includes(tag));
            if (!tagsCheck) {
                continue;
            }
        }

        let p = trade.p; // prem
        if (isNaN(p)) {
            continue;
        };

        let pCheck = ((alert_.minPremium ? p >= alert_.minPremium : true) && (alert_.maxPremium ? p <= alert_.maxPremium : true));
        if (!pCheck) {
            continue;
        };
        let op = trade.op; // otm percentage
        if (isNaN(op)) {
            continue;
        };

        let OTMCheck = ((alert_.minOTM ? op >= alert_.minOTM : true) && (alert_.maxOTM ? op <= alert_.maxOTM : true));
        if (!OTMCheck) {
            continue;
        };
        let sc = trade.sc; // sc ore
        // let last7DaysBullflowVolume = trade.last7DaysBullflowVolume;
        // let previousBullflowVolume = trade.previousBullflowVolume;
        let spot = trade.sp;
        let optionPrice = trade.tp; // trade price
        let mc = trade.mc; // market cap
        let previousVolume = trade.previousVolume;
        let cv = trade.cv;
        let oi = trade.oi;
        // //console.log(`Checking trade filter. Parsed Market cap = ${mc}`);
        if (isNaN(sc) || (isNaN(spot) && spot != null)
            || (isNaN(optionPrice) && optionPrice != null)
            || (isNaN(mc) && mc != null)
            // || (isNaN(last7DaysBullflowVolume) && last7DaysBullflowVolume != null)
            // || (isNaN(previousBullflowVolume) && previousBullflowVolume != null)
            || (isNaN(previousVolume) && previousVolume != null)
            || (isNaN(cv) && cv != null)
            || (isNaN(oi) && oi != null)) {
            continue;
        };
        let filterOIMax = parseFloat(alert_.maxOI);

        if (filterOIMax >= MAX_OI) {
            filterOIMax = 99999999;
        }

        if (oi > filterOIMax) {
            continue;
        }


        let filterCurrentVolumeMax = parseFloat(alert_.maxCurrentVolume);

        if (filterCurrentVolumeMax >= MAX_CURRENT_VOLUME) {
            filterCurrentVolumeMax = 99999999;
        }

        if (cv > filterCurrentVolumeMax) {
            continue;
        }
        // //console.log(` ${sc} >= ${parseScoreMin}  && ${sc} <= ${parseScoreMax}`);
        // let filterLast7DaysBFVolumeMax = parseFloat(alert_.maxLast7DaysBFVolume);
        // let filterPrevDayBFVolumeMax = parseFloat(alert_.maxPrevDayBFVolume);
        // if (filterLast7DaysBFVolumeMax >= MAX_LAST_7_DAYS_BF_VOLUME) {
        //     // console.log('filterLast7DaysBFVolumeMax >= MAX_LAST_7_DAYS_BF_VOLUME');
        //     filterLast7DaysBFVolumeMax = 999999;
        // }
        // if (filterPrevDayBFVolumeMax >= MAX_PREV_DAY_BF_VOLUME) {
        //     // console.log('filterPrevDayBFVolumeMax >= MAX_PREV_DAY_BF_VOLUME');
        //     filterPrevDayBFVolumeMax = 999999;
        // }
        // if (last7DaysBullflowVolume > filterLast7DaysBFVolumeMax || previousBullflowVolume > filterPrevDayBFVolumeMax) {
        //     continue;
        // }

        let parseScoreMin = parseFloat(alert_.minScore);
        let parseScoreMax = parseFloat(alert_.maxScore);
        let scCheck = ((parseScoreMin ? sc >= parseScoreMin : true) && (parseScoreMax ? sc <= parseScoreMax : true));
        if (!scCheck) {
            //console.log(`scCheck false parseMin:${parseScoreMin} parseMax: ${parseScoreMax} sc:${sc}`);
            continue;
        };


        let parseMinSpot = parseFloat(alert_.minSpot);
        let parseMaxSpot = parseFloat(alert_.maxSpot);
        let spotCheck = ((parseMinSpot ? spot >= parseMinSpot : true) && (parseMaxSpot ? spot <= parseMaxSpot : true));
        if (!spotCheck) {
            continue;
        };

        let parseMinOptionPrice = parseFloat(alert_.minOptionPrice);
        let parseMaxOptionPrice = parseFloat(alert_.maxOptionPrice);
        let optionPriceCheck = ((parseMinOptionPrice ? optionPrice >= parseMinOptionPrice : true) && (parseMaxOptionPrice ? optionPrice <= parseMaxOptionPrice : true));
        if (!optionPriceCheck) {
            continue;
        };

        let parseMinMarketCap = isNaN(parseFloat(alert_.minMarketCap)) ? 0 : parseFloat(alert_.minMarketCap);
        let parseMaxMarketCap = isNaN(parseFloat(alert_.maxMarketCap)) ? 0 : parseFloat(alert_.maxMarketCap);
        let mcCheck = ((parseMinMarketCap ? mc >= parseMinMarketCap : true) && (parseMaxMarketCap ? mc <= parseMaxMarketCap : true));
        if (!mcCheck) {
            continue;
        } else if (mcCheck && mc === "N/A") {
            continue;
        }


        // check ticker exclusions
        let excludeCheck = true;
        if (Array.isArray(alert_.excludeTickers)) {
            excludeCheck = alert_.excludeTickers.length > 0 ? !alert_.excludeTickers.includes(trade.tsy) : true;
            if (!excludeCheck) {
                // //console.log("excludeCheck false");
                continue;
            };
        }
        let showCheck = true;
        if (Array.isArray(alert_.onlyShowTickers)) {
            showCheck = alert_.onlyShowTickers.length > 0 ? alert_.onlyShowTickers.includes(trade.tsy) : true;
            if (!showCheck) {
                continue;
            };
        }

        let parseMinDTE = parseInt(alert_.minDTE);
        let parseMaxDTE = parseInt(alert_.maxDTE);
        if (isNaN(parseMinDTE)) {
            parseMinDTE = 0;
        }
        if (isNaN(parseMaxDTE)) {
            parseMaxDTE = 9999;
        }

        let fullYearDate = yearPrefix + trade.ed; // ed = expiration date
        let ed = new Date(fullYearDate);

        let timeDiff = ed - today;

        let daysTillExpiration = Math.ceil(timeDiff / secondsCalc);  // convert milliseconds to days

        let expirationCheck = ((parseMinDTE ? daysTillExpiration >= parseMinDTE : true) && (parseMaxDTE ? daysTillExpiration <= parseMaxDTE : true));
        if (!expirationCheck) {
            continue;
        };
        let callsCheck = true;
        let putsCheck = true;
        if (!alert_.callsCheckBox && trade.ot === "C") {
            continue;
        }
        if (!alert_.putsCheckBox && trade.ot === "P") {
            continue;
        }

        if (!alert_.aaCheckBox && (trade.se === "Ask" || trade.se === "Above Ask")) {
            continue;
        }
        if (!alert_.bbCheckBox && (trade.se === "Bid" || trade.se === "Below Bid")) {
            continue;
        }
        if (!alert_.spCheckBox && trade.se === "Mid") {
            continue;
        }

        if (!alert_.bullishCheckBox && trade.tks === 1) {
            continue;
        }
        if (!alert_.bearishCheckBox && trade.tks === -1) {
            continue;
        }
        if (!alert_.neutralCheckBox && trade.tks === 0) {
            continue;
        }

        let tradesCheck = true;
        let sweepsCheck = true;
        if (!alert_.multilegCheckbox && trade.tt === "MULTI") {
            continue;
        }
        if (!alert_.splitsCheckBox && trade.tt === "SPLIT") {
            continue;
        }
        if (!alert_.sweepsCheckBox && trade.tt === "SWEEP") {
            continue;
        }
        if (!alert_.singlesCheckbox && trade.tt === "SINGLE") {
            continue;
        }
        if (!alert_.blocksCheckbox && trade.tt === "BLOCK") {
            continue;
        }
        let sectorCheck;

        if (!sectors.includes(alert_.sectorFilter) || alert_.sectorFilter === "ALL") {
            sectorCheck = true;
        } else {
            sectorCheck = (trade.s === alert_.sectorFilter ? true : false)
        }

        if (pCheck && OTMCheck && sectorCheck && excludeCheck && showCheck && expirationCheck && scCheck && callsCheck && putsCheck && tradesCheck && sweepsCheck && spotCheck && optionPriceCheck && mcCheck) {
        
            // addCustomAlert(trade, alertName, alert_.email);
            const options = {
                body: `${trade.tsy}, ${formatNumber(trade.p)} ${trade.ot}`,
                icon: './bullflow_3.png', // URL of an icon image
                badge: './bullflow_3.png', // URL of a small badge icon
            };
            playNotificationSound(isSoundOn);
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
            }
            // Check whether notification permissions have already been granted
            else if (Notification.permission === "granted") {
                new Notification(`Alert Triggered: ${alertName}`, options);
            }
            else if (Notification.permission === "denied") {
                //console.log("NOTIFICATION PERMISSION DENIED");
                alert("You have blocked notifications. Please enable them in your browser settings if you wish to receive alerts.");
            }
            // Otherwise, we need to ask the user for permission
            else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(function (permission) {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        new Notification(`Bullflow alert: ${alertName}`, options);
                    } else {
                        //console.log("PERMISSION STILL NOT GRANTED");
                    }
                });
            }
        };
    }
}

const playNotificationSound = (isSoundOn) => {
    if (isSoundOn) {
        const audio = new Audio(alertSound);
        audio.play().catch(e => console.log("Audio play failed:", e));
    }
};

export default checkAlertsWithTrade;