// CustomFlowWindowManager.jsx

import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import { FixedSizeList as List } from 'react-window';
import { X } from 'lucide-react';
import { collection, addDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';
import getDateString, { miniUnpackAndCompleteTrade } from '../Utils';

// VirtualizedList subscribes to Firestore and renders each document's "sym", "p", and "ut" values.
const VirtualizedList = ({ height }) => {
  const [items, setItems] = useState([]);
  const headerHeight = 35;

  useEffect(() => {
    const collectionName=  "_MIKE_FLOW/options/" + getDateString();
    const colRef = collection(firestore, collectionName);
    const unsubscribe = onSnapshot(colRef, (snapshot) => {
        console.log(`Received snapshot with ${snapshot.docs.length} documents`);
      const docs = snapshot.docs.map((doc) => (
        miniUnpackAndCompleteTrade({ id: doc.id, ...doc.data() })
    ));
      setItems(docs);
    });
    return () => unsubscribe();
  }, []);

  // Custom inner element that injects a sticky header at the top.
  const InnerElementType = React.forwardRef(({ style, ...rest }, ref) => (
    <div ref={ref} style={{ ...style, paddingTop: headerHeight }} {...rest}>
      <div
        className="sticky top-0 flex bg-gray-800 text-white p-2 z-10"
        style={{ height: headerHeight }}
      >
        <div className="w-1/3 font-bold">Sym</div>
        <div className="w-1/3 font-bold">P</div>
        <div className="w-1/3 font-bold">UT</div>
      </div>
      {rest.children}
    </div>
  ));

  return (
    <List
      height={height}
      itemCount={items.length}
      itemSize={35}
      width="100%"
      innerElementType={InnerElementType}
    >
      {({ index, style }) => {
        const doc = items[index];
        // Alternate row backgrounds: black for even rows, dark gray for odd rows.
        const bgColor = index % 2 === 0 ? 'bg-black' : 'bg-[#171717]';
        return (
          <div
            style={style}
            className={`${bgColor} flex items-center px-2 text-white`}
          >
            <div className="w-1/3">{doc.sym || "-"}</div>
            <div className="w-1/3">{doc.p !== undefined ? doc.p : "-"}</div>
            <div className="w-1/3">{doc.ut !== undefined ? doc.ut : "-"}</div>
          </div>
        );
      }}
    </List>
  );
};

// CustomWindow renders a draggable/resizable window.
// The title bar is the drag handle and includes a close button using lucide-react's X icon.
const CustomWindow = ({ id, x, y, width, height, title, onBringToFront, onClose }) => {
  const [dimensions, setDimensions] = useState({ width, height, x, y });
  
  // Define the height for the title bar and container padding.
  const windowTitleHeight = 35;
  const containerPadding = 16; // Approximate padding (8px top and bottom)
  // Compute available height for the virtualized list.
  const listHeight = dimensions.height - windowTitleHeight - containerPadding;

  // Handle the close action without triggering drag events.
  const handleClose = (e) => {
    e.stopPropagation();
    onClose(id);
  };

  return (
    <Rnd
      size={{ width: dimensions.width, height: dimensions.height }}
      position={{ x: dimensions.x, y: dimensions.y }}
      bounds="parent"
      dragHandleClassName="drag-handle"
      onDragStop={(e, d) => {
        setDimensions((prev) => ({ ...prev, x: d.x, y: d.y }));
        onBringToFront(id);
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setDimensions({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          x: position.x,
          y: position.y,
        });
      }}
      className="bg-black border border-gray-800 shadow-lg rounded"
    >
      <div className="flex flex-col h-full">
        {/* Title bar with title text and close button */}
        <div
          className="flex items-center justify-between bg-gray-800 text-white p-2 rounded-t"
          style={{ height: windowTitleHeight }}
        >
          <div className="drag-handle flex-1">
            {title || `Window ${id}`}
          </div>
          <button
            onClick={handleClose}
            className="p-1 hover:text-red-400 focus:outline-none"
          >
            <X size={16} />
          </button>
        </div>
        {/* Content area with virtualized list filling the remaining height */}
        <div className="p-2 text-white flex-1">
          <VirtualizedList height={listHeight} />
        </div>
      </div>
    </Rnd>
  );
};

const CustomFlowWindowManager = () => {
  const [windows, setWindows] = useState([]);

  // Adds a new window.
  const addWindow = () => {
    const newWindow = {
      id: Date.now(), // Unique id.
      x: 100,
      y: 100,
      width: 300,
      height: 300,
    };
    setWindows((prev) => [...prev, newWindow]);
  };

  // Bring the window with the specified id to the front.
  const bringToFront = (id) => {
    setWindows((prev) => {
      const index = prev.findIndex((win) => win.id === id);
      if (index === -1) return prev;
      const windowItem = prev[index];
      const newWindows = [...prev];
      newWindows.splice(index, 1);
      return [...newWindows, windowItem];
    });
  };

  // Remove a window from the state.
  const closeWindow = (id) => {
    setWindows((prev) => prev.filter(win => win.id !== id));
  };

  return (
    <div className="relative w-full h-screen bg-[#1a1b20] overflow-hidden">
      {/* "Add +" button */}
      <button
        onClick={addWindow}
        className="absolute top-4 left-4 z-10 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
      >
        Add +
      </button>
      {/* Render each custom window (last rendered window appears on top) */}
      {windows.map((win) => (
        <CustomWindow
          key={win.id}
          id={win.id}
          x={win.x}
          y={win.y}
          width={win.width}
          height={win.height}
          title={`Window ${win.id}`}
          onBringToFront={bringToFront}
          onClose={closeWindow}
        />
      ))}
    </div>
  );
};

export default CustomFlowWindowManager;
