import React, { useEffect, useState, useRef, useCallback } from "react";
import UpdatedCallPutBar from "./UpdatedCallPutBar";
import PropTypes from "prop-types";
import { formatNumber, isAfterMarketHours } from "../../Utils";
import { Tooltip } from "@material-ui/core";
import { HelpCircle } from "lucide-react";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";

const BULLFLOW_GREEN = "#20d46b";
const BULLFLOW_RED = "#cc3753";
const BULLFLOW_OPACITY_GREEN = "rgba(2, 212, 44, 0.15)";
const BULLFLOW_OPACITY_RED = "rgba(250, 0, 67, 0.15)";

export default function LiveTickerDetails({
    currentCallPutData = {
        callCount: 0,
        putCount: 0,
        callPremium: 0,
        putPremium: 0,
        askCount: 0,
        bidCount: 0,
        askPremium: 0,
        bidPremium: 0,
        bullishPremium: 0,
        bearishPremium: 0,
    },
    contractOrTickerSymbol = "O:SPY250130C00603000",
}) {
    const [contractVolume, setContractVolume] = useState(0);
    const [optionContract, setOptionContract] = useState(false);
    const [dailyPremiumAverage, setDailyPremiumAverage] = useState(0);
    const [percentDifference, setPercentDifference] = useState(null);
    const [markPriceFlash, setMarkPriceFlash] = useState(null);
    const prevMarkPrice = useRef(null);
    const [markPriceHistory, setMarkPriceHistory] = useState([]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(100);
    const [currentMarkPrice, setCurrentMarkPrice] = useState(0);
    const [isTabVisible, setIsTabVisible] = useState(!document.hidden); // Track tab visibility
    const timeoutIdRef = useRef(null); // Store timeout ID for cleanup

    // Fetch initial intraday marks
    const fetchInitialIntradayMarks = useCallback(async (symbol, signal) => {
        try {
            const response = await fetch(
                `https://api.bullflow.io/v1/intradayMarks?Symbol=${encodeURIComponent(symbol)}`,
                { signal }
            );
            if (!response.ok) throw new Error("Network response was not ok");
            const data = await response.json();
            const initialMarks = data.map(item => parseFloat(item.mark.toFixed(2)));
            setMarkPriceHistory(initialMarks);
            if (initialMarks.length) {
                setMinPrice(Math.min(...initialMarks));
                setMaxPrice(Math.max(...initialMarks));
            }
        } catch (error) {
            if (error.name !== "AbortError") {
                console.error("Error fetching initial intraday marks:", error);
            }
        }
    }, []);

    // Fetch bid/ask data
    const fetchBidAskData = useCallback(async (symbol) => {
        try {
            const isOptionContract = symbol.substring(0, 2) === "O:";
            setOptionContract(isOptionContract);
            if (isOptionContract && isAfterMarketHours()) return;

            const apiUrl = isOptionContract
                ? `https://api.bullflow.io/v1/contractSpreadData?Symbol=${encodeURIComponent(symbol)}`
                : `https://api.bullflow.io/v1/tickerSpreadData?r=${encodeURIComponent(symbol)}`;

            const response = await fetch(apiUrl);
            if (!response.ok) throw new Error("Network response was not ok");
            const data = await response.json();

            const newMarkPrice = data.mark;

            setMarkPriceHistory((prevHistory) => {
                let updatedHistory = [...prevHistory, parseFloat(newMarkPrice.toFixed(2))].slice(-450);

                if (updatedHistory.length === 2) {
                    const [first, second] = updatedHistory;
                    const percentDiff = Math.abs((first - second) / second);
                    if (percentDiff > 0.02) {
                        updatedHistory.shift();
                    }
                }

                if (updatedHistory.length) {
                    setMinPrice(Math.min(...updatedHistory));
                    setMaxPrice(Math.max(...updatedHistory));
                }

                return updatedHistory;
            });

            if (prevMarkPrice.current !== null && newMarkPrice.toFixed(2) !== prevMarkPrice.current.toFixed(2)) {
                setMarkPriceFlash(newMarkPrice.toFixed(2) > prevMarkPrice.current.toFixed(2) ? "green" : "red");
                if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
                timeoutIdRef.current = setTimeout(() => {
                    setMarkPriceFlash(null);
                }, 500);
            }
            prevMarkPrice.current = newMarkPrice;

            setCurrentMarkPrice(newMarkPrice);
            setContractVolume(data.volume);
            setDailyPremiumAverage(
                isOptionContract ? data.contract_daily_premium_average : data.stock_daily_premium_average
            );

            if (!isOptionContract && data.percent_difference !== undefined) {
                setPercentDifference(data.percent_difference);
            } else {
                setPercentDifference(null);
            }
        } catch (error) {
            console.error("Error fetching bid/ask data:", error);
        }
    }, []);

    // Handle tab visibility changes
    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsTabVisible(!document.hidden);
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    // Fetch initial data when contractOrTickerSymbol changes
    useEffect(() => {
        if (!contractOrTickerSymbol) {
            console.warn("No contractOrTickerSymbol provided.");
            return;
        }

        // Reset state
        setMarkPriceHistory([]);
        setMinPrice(0);
        setMaxPrice(100);

        const abortController = new AbortController();
        const signal = abortController.signal;

        fetchInitialIntradayMarks(contractOrTickerSymbol, signal).then(() => {
            fetchBidAskData(contractOrTickerSymbol);
        });

        return () => {
            abortController.abort();
            if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
        };
    }, [contractOrTickerSymbol, fetchInitialIntradayMarks, fetchBidAskData]);

    // Set up interval for bid/ask data when tab is visible
    useEffect(() => {
        if (!isTabVisible) return;

        const intervalId = setInterval(() => {
            fetchBidAskData(contractOrTickerSymbol);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [isTabVisible, contractOrTickerSymbol, fetchBidAskData]);

    const {
        callCount,
        putCount,
        callPremium,
        putPremium,
        askCount,
        bidCount,
        askPremium,
        bidPremium,
        bullishPremium,
        bearishPremium,
    } = currentCallPutData;

    const markPrice = currentMarkPrice.toFixed(2);

    return (
        <div
            style={{
                display: "flex",
                width: "60%",
                minHeight: "100%",
                backgroundColor: "#000000",
                borderRadius: "10px",
                color: "#ffffff",
                padding: "6px",
                boxSizing: "border-box",
                gap: "16px",
            }}
        >
            {/* Left Column: Bid/Ask Data & Sparklines */}
            <div
                style={{
                    display: "flex",
                    flex: "1",
                    padding: "0px",
                    gap: "0px",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <SparkLineChart
                        data={markPriceHistory}
                        curve="natural"
                        area
                        showHighlight
                        showTooltip
                        colors={[
                            percentDifference !== null && !isNaN(percentDifference)
                                ? Number(percentDifference) >= 0.0
                                    ? BULLFLOW_GREEN
                                    : BULLFLOW_RED
                                : BULLFLOW_GREEN,
                        ]}
                        yAxis={{
                            min: minPrice,
                            max: maxPrice,
                            scaleType: "linear",
                        }}
                        sx={{
                            "& .MuiLineElement-root": {
                                strokeWidth: 2,
                            },
                            "& .MuiAreaElement-root": {
                                fillOpacity: 0.3,
                            },
                            "& .MuiTooltip-tooltip": {
                                backgroundColor: "rgba(66, 66, 66, 0.5)",
                                color: "#ffffff",
                                fontSize: "0.875rem",
                            },
                            width: "100%",
                            flexGrow: 1,
                            margin: "0px",
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            margin: "0px",
                        }}
                    >
                        <span
                            style={{
                                fontSize: "0.75rem",
                                color: "gray",
                                margin: "0px",
                            }}
                        >
                            15m
                        </span>
                        <span
                            style={{
                                fontSize: "0.75rem",
                                color: "gray",
                                margin: "0px",
                            }}
                        >
                            now
                        </span>
                    </div>
                </div>
            </div>

            {/* Center Column: Other Data */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: "1",
                    padding: "8px",
                    boxSizing: "border-box",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridGap: "2px",
                        width: "100%",
                        padding: "8px",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <span style={{ fontSize: "0.75rem", color: "gray" }}>Mark</span>
                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                            <span
                                style={{
                                    fontSize: "0.875rem",
                                    transition: "color 0.1s",
                                    color:
                                        markPriceFlash === "green"
                                            ? BULLFLOW_GREEN
                                            : markPriceFlash === "red"
                                            ? BULLFLOW_RED
                                            : "#ffffff",
                                }}
                            >
                                ${markPrice}
                            </span>
                            {!optionContract && percentDifference !== null && (
                                <Tooltip title="% change from yesterday's closing price" placement="top">
                                    <span
                                        style={{
                                            fontSize: "0.75rem",
                                            color:
                                                Number(percentDifference) > 0 ? BULLFLOW_GREEN : BULLFLOW_RED,
                                            cursor: "pointer",
                                        }}
                                    >
                                        ({Number(percentDifference) >= 0 ? "+" : ""}
                                        {Number(percentDifference).toFixed(2)}%)
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                            <span style={{ fontSize: "0.75rem", color: "gray" }}>Premium Avg.</span>
                            <Tooltip
                                title={
                                    optionContract
                                        ? "Average daily premium total for this option contract"
                                        : "Average daily premium total for this stock"
                                }
                                placement="top"
                            >
                                <span>
                                    <HelpCircle size={14} color="gray" style={{ cursor: "pointer" }} />
                                </span>
                            </Tooltip>
                        </div>
                        <span style={{ fontSize: "0.875rem" }}>{formatNumber(dailyPremiumAverage)}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <span style={{ fontSize: "0.75rem", color: "gray" }}>
                            {optionContract ? "Bid $" : "Bear $"}
                        </span>
                        <span style={{ fontSize: "0.875rem" }}>
                            ${formatNumber(optionContract ? bidPremium : bearishPremium)}
                        </span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <span style={{ fontSize: "0.75rem", color: "gray" }}>
                            {optionContract ? "Ask $" : "Bull $"}
                        </span>
                        <span style={{ fontSize: "0.875rem" }}>
                            ${formatNumber(optionContract ? askPremium : bullishPremium)}
                        </span>
                    </div>
                </div>
            </div>

            {/* Right Column: CallPutBar */}
            <div
                style={{
                    display: "flex",
                    flex: "1",
                    padding: "8px",
                    gap: "12px",
                    alignItems: "center",
                }}
            >
                <UpdatedCallPutBar
                    initialPutValue={putCount}
                    initialCallValue={callCount}
                    initialPutPremium={putPremium}
                    initialCallPremium={callPremium}
                />
            </div>
        </div>
    );
}

LiveTickerDetails.propTypes = {
    currentCallPutData: PropTypes.shape({
        callCount: PropTypes.number,
        putCount: PropTypes.number,
        callPremium: PropTypes.number,
        putPremium: PropTypes.number,
    }),
    contractOrTickerSymbol: PropTypes.string.isRequired,
};