import React, { useState, useEffect } from 'react';

const TitleAndSubtitle = ({
    titleText = "The #1 Flow Trading Platform & Community",
    subtitleText = "Learn to follow the options trades of hedge funds and institutional quants in real-time",
}) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <style>
                {`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
                `}
            </style>
            <div
                style={{
                    textAlign: 'center',
                    width: '100%',
                    marginTop: isSmallScreen ? '0px' : '0px',
                }}
            >
                <h1
                    id="hero-title"
                    style={{
                        opacity: 0,
                        animation: 'fadeIn 1.25s ease-out forwards',
                        zIndex: 2,
                        position: 'relative',
                        color: '#fff',
                        fontWeight: 700,
                        fontSize: 'clamp(24px, 3.35vw, 48px)',
                        lineHeight: '1.2',
                        marginBottom: '18px',
                    }}
                >
                    <span
                        style={{
                            background: 'linear-gradient(to right, #8593ff, #fff, #fce774)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        {titleText}
                    </span>
                </h1>
                <h2
                    id="hero-subtitle"
                    style={{
                        opacity: 0,
                        animation: 'fadeIn 1.25s ease-out 0.45s forwards',
                        zIndex: 2,
                        position: 'relative',
                        color: '#b0b0b0',
                        fontWeight: 450,
                        fontSize: 'clamp(16px, 1.25vw, 42px)',
                        // make italic
                        lineHeight: '1.2',
                        marginBottom: '50px',
                    }}
                >
                    {subtitleText}
                </h2>

                <img
                    src="https://firebasestorage.googleapis.com/v0/b/liteflow-40a37.appspot.com/o/mac_phone_promo_image.png?alt=media&token=263a938f-9716-4fdd-88e5-34114e459e34"
                    alt="Hero"
                    style={{
                        opacity: 0,
                        animation: 'fadeIn 1.25s ease-out 0.85s forwards',
                        maxWidth: '100%',
                        height: 'auto',
                        zIndex: 1,
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
            </div>
        </>
    );
};

export default TitleAndSubtitle;
